/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }
.owl-carousel .owl-item img {
  display: block;
  width: 100%; }
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }
.owl-carousel.owl-loaded {
  display: block; }
.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }
.owl-carousel.owl-hidden {
  opacity: 0; }
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }
.owl-carousel.owl-rtl {
  direction: rtl; }
.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

//////////////////////
// Owl Carousel
//////////////////////

/* USAGE:
<div class="slider-home owl-carousel-container">
  <div class="owl-carousel">
    <div class="slider-home-item">
    </div>
  </div>
  <div class="owl-custom-dots-container">
    <div class="container">
      <div class="owl-custom-dots"></div>
    </div>
  </div>
</div>
*/

// disabled
.owl-dot,
.owl-stage {
  &.disabled,
  &[disabled] {
    opacity: 1;
    filter: alpha(opacity=100);
    pointer-events: none;
  }
}

// slide animation
.owl-stage {
  will-change: transform;
  html.mobile .owl-carousel.owl-loaded.owl-custom-stage-transition:not(.owl-grab) &,
  .owl-carousel.owl-loaded.owl-custom-stage-transition:not(.owl-grab):not(.owl-resize) & {
    -webkit-transition: -webkit-transform 400ms @bez-in !important;
    transition: transform 400ms @bez-in !important;
    @media @min-sm {
      -webkit-transition: -webkit-transform 750ms @bez-in !important;
      transition: transform 750ms @bez-in !important;
    }
    &.no-drag {
      -webkit-transition: none !important;
      transition: none !important;
    }
  }
}

/*
// in and out animation
.owl-item.animated.owl-animated-out {
  z-index: 0;
  .anim-horizontal.out();
  animation-fill-mode: forwards;
  opacity: 1;
  .slider-home-item-inner {
    .anim-horizontal.out();
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.owl-item.animated.owl-animated-in {
  z-index: 1;
  .anim-horizontal.active();
  animation-fill-mode: forwards;
  opacity: 0;
  // .slider-home-item
  .slider-home-item-inner .container > * {
    .anim-horizontal.active();
    animation-fill-mode: forwards;
    opacity: 0;
    transition-delay: 150ms; // @start + @child * @increment
    .nth-child(@child:3, @start:0ms, @increment:50ms, @decrement:0ms, {
      @animation-delay();
    });
  }
}
*/

// height animation
.owl-height {
  -webkit-transition: height 350ms @bez-in !important;
  transition: height 350ms @bez-in !important;
  .owl-carousel.owl-resize & {
    -webkit-transition: none !important;
    transition: none !important;
  }
}

// center stage when items aren't enough
// NEEDS loop: false,
/*
.slider-products .owl-stage {
  margin: 0 auto;
}
*/

// .owl-unique
.owl-unique {
  text-align: center;
  > * {
    display: inline-block;
  }
}

/*
// .img-responsive vertical on mobile
// NEEDS autoWidth: true and add js // autoWidth fix
@media @max-sm {
  .slider-media-item .img-responsive {
    width: auto !important;
    height: 200px;
  }
}
*/

/*
// disable slider on mobile
@media @max-sm {
  .slider-listing {
    .owl-stage-outer {
      height: auto !important;
    }
    .owl-stage {
      transform: none !important;
      width: 100% !important;
    }
    .owl-item.cloned {
      display: none !important;
    }
    .owl-custom-dots-container {
      display: none;
    }
  }
}
*/

/*
// center animation
.slider-media {
  .owl-item {
    opacity: 0.3;
    filter: alpha(opacity=30);
    -webkit-transition: opacity 1200ms @bez-out;
    transition: opacity 1200ms @bez-out;
  }
  .owl-item.center {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1200ms @bez-in;
    transition: opacity 1200ms @bez-in;
  }
}
*/

// nav
/* USAGE:
<div class="owl-custom-nav" id="owl-custom-nav-0"></div>
*/

.owl-custom-nav {
  .user-select-none();
  cursor: default;
  font-size: 0;
  &.disabled {
    .owl-prev,
    .owl-next {
      display: none;
    }
  }
  @media @max-sm {
    display: none;
  }
  &.owl-custom-nav--mobile {
    @media @max-sm {
      display: block;
    }
    @media @min-sm {
      display: none;
    }
  }
  &.owl-custom-nav--always {
    @media @max-sm {
      display: block;
    }
    @media @min-sm {
      display: block;
    }
  }
  .owl-prev,
  .owl-next {
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 4;
    height: 100%;
    top: 50%;
    height: auto;
    &.disabled {
      pointer-events: initial;
    }
    .margin-remove(top, big);
    .padding(all, medium);
    &:before {
      content: '';
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      color: @black;
      line-height: 1;
      text-align: center;
      .owl-custom-nav--white& {
        color: @white;
      }
    }
    &.disabled {
      cursor: default;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
    // animations
    .anim-zoom.out();
    animation-fill-mode: forwards;
    &:hover:not(.disabled) {
      .anim-zoom.active();
      animation-fill-mode: forwards;
    }
  }
  .owl-prev {
    left: 0;
    .slider-home& {
      left: 1.6rem;
    }
    &:before {
      content: '\e904';
    }
  }
  .owl-next {
    right: 0;
    .slider-home& {
      right: 1.6rem;
    }
    &:before {
      content: '\e905';
    }
  }
}

/*
// nav animations
.slider-home& {
  .owl-custom-nav {
    .owl-prev,
    .owl-next {
      // animations
      opacity: 0;
      -webkit-transition: opacity 300ms @bez-out;
      transition: opacity 300ms @bez-out;
    }
  }
  &:hover .owl-custom-nav {
    &:not(.disabled) {
      .owl-prev,
      .owl-next {
        opacity: 1;
        -webkit-transition: opacity 300ms @bez-in;
        transition: opacity 300ms @bez-in;
      }
    }
  }
}
*/

/*
// .owl-custom-nav
.owl-custom-nav {
  .no-selection;
  cursor: default;
  float: right;
  margin-right: -1.6rem;
  white-space: nowrap;
  font-size: 0;
  .owl-prev,
  .owl-next {
    cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 4;
    padding: 1.6rem;
    &:before {
      content: '';
      font-family: 'bodysupply' !important;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      color: @color-black;
      line-height: 1;
      text-align: center;
    }
    &.disabled {
      cursor: default;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
    // animations
    &:before {
      -webkit-transition: color 300ms @bez-out;
      transition: color 300ms @bez-out;
    }
    &:not(.disabled) {
      &:hover {
        &:before {
          color: @color-grey700;
          -webkit-transition: color 300ms @bez-in;
          transition: color 300ms @bez-in;
        }
      }
    }
  }
  .owl-prev {
    &:before {
      content: '\e90e';
    }
  }
  .owl-next {
    &:before {
      content: '\e90f';
    }
  }
}
*/

// .owl-custom-dots

/* USAGE onside items
<div class="owl-custom-dots-container">
  <div class="container">
    <div class="owl-custom-dots"></div>
  </div>
</div>
*/
.owl-custom-dots-container {
  position: absolute;
  z-index: 4;
  // .owl-custom-dots bottom
  position: absolute;
  z-index: 4;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  .accelerate();
  // .owl-custom-dots inside
  /*
  position: relative;
  z-index: 4;
  margin-left: -1.6rem;
  margin-top: 1.6rem;
  min-height: 25px;
  */
}
.owl-custom-dots {
  .owl-dot {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 0 2px;
    width: 30px;
    height: 30px;
    span {
      display: none;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: white;
      .slider-home & {
        @media @max-sm {
          background-color: black;
        }
      }
    }
    // animations
    &.active,
    &:hover {
      &:before {
        height: 4px;
      }
    }
    &.active {
      cursor: default;
    }
  }
}
