//////////////////////
// container
//////////////////////

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: @container-fluid;
}

//////////////////////
// grid
//////////////////////

.col {
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: 100%; // fix ie10 overflow
  flex-basis: auto;
  max-width: none;
  width: auto;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

// grid

.col(@number, @breakpoint:none) when (@breakpoint = none) {
  @val: (percentage(@number / @columns));
  // NO BUGS IE11
  //-webkit-flex-basis: @val;
  //-ms-flex-preferred-size: @val;
  //flex-basis: @val;
  max-width: @val;
  width: @val;
}

.col(@number, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @val: (percentage(@number / @columns));
  @media @@query-min {
    // NO BUGS IE11
    //-webkit-flex-basis: @val;
    //-ms-flex-preferred-size: @val;
    //flex-basis: @val;
    max-width: @val;
    width: @val;
  }
}

//////////////////////
// flex
//////////////////////

.flex {
  .display(flex);
}
.flex-inline {
  .display(inline-flex);
  width: 100%;
}

//////////////////////
// list
//////////////////////

.list-base() {
  .display(flex);
  .reset-list();
  //.flex-auto(); // @FIX flex inside flex take 100% but dont bugs negative margins
  // list
  > li {
    .display(flex);
    .align-items-start();
    > * {
      .flex-auto();
    }
  }
  .list-btn-all({ .block(); .text-align(left); });
  .list-btn-default({ .btn(); });
}

// selectors

.list-btn-all(@rules) {
  > li {
    > a, > button {
      @rules();
    }
  }
}

.list-btn-default(@rules) {
  > li {
    > a, > button {
      &:not(.btn) {
        @rules();
      }
    }
  }
}

// .list

.list {
  .list-base();
  .flex-row();
  .flex-wrap();
}

.list-block {
  .flex-column();
  .flex-nowrap();
}

//////////////////////
// box
//////////////////////

.box {
  .display(flex);
  .flex-column();
  position: relative;
}

/*
//////////////////////
// direction
//////////////////////

// vertical

.vertical(@breakpoint:none) when (@breakpoint = none) {
  .flex-column();
  .flex-wrap();
  &.reverse {
    .flex-column();
  }
}
.vertical(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    .flex-column();
    .flex-wrap();
    &.reverse {
      .flex-column-reverse();
    }
  }
}

// horizontal

.horizontal(@breakpoint:none) when (@breakpoint = none) {
  .flex-row();
  .flex-nowrap();
  &.reverse {
    .flex-row-reverse();
  }
}
.horizontal(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    .flex-row();
    .flex-nowrap();
    &.reverse {
      .flex-row-reverse();
    }
  }
}

& when (@classes-layout) {
  
  // generate-directions
  
  .generate-directions();
  .generate-directions(@breakpoint:none) {
    & when (@breakpoint = none) {
      .vertical {
        .vertical();
      }
      .horizontal {
        .horizontal();
      }
    }
    & when not (@breakpoint = none) {
      @query-min: 'min-@{breakpoint}';
      @media @@query-min {
        .vertical-@{breakpoint} {
          .vertical();
        }
        .horizontal-@{breakpoint} {
          .horizontal();
        }
      }
    }
  }
  
  // generate-directions-responsive
  
  & when not (@classes-breakpoint = false) {
    .generate-directions-responsive();
    .generate-directions-responsive(@i:length(@classes-breakpoint)) when (@i > 0) {
      .generate-directions-responsive(@i - 1);
      @breakpoint: extract(@classes-breakpoint, @i);
      .generate-directions(@breakpoint);
    }
  }
  
}
*/