//////////////////////
// header
//////////////////////

// .header-content

.header-content {
  .header-main-logo {
    .padding(y, 1rem);
    @media @min-md {
      .padding(y, 2.5rem);
    }
    .nav-toggle {
      .display(none);
    }
    .logo {
      .display(block);
      img {
        width: auto;
        height: 35px;
        @media @min-md {
          height: 55px;
        }
      }
    }
  }
  .nav-toggle {
    .icon(@content: @icon-menu, @size: @icon-big, @sr-only: true);
    html.nav-open & {
      .icon(@content: @icon-close, @size: @icon-big, @sr-only: true);
    }
  }
}

// .header-main

.header-main {
  position: relative; // needed for mobile static dropdowns
  z-index: 12;
  .display(flex);
  background-color: @white;
  color: @text;
  .header-main-inner {
    .flex-space(none);
    .justify-content-center();
    .align-items-stretch();
    > * {
      .display(flex);
      .align-items-stretch();
    }
  }
  .header-main-center {
    .flex-auto();
    .display(flex);
    .justify-content-center();
  }
  .header-main-left {
    .flex-auto();
    .display(flex);
    .justify-content-start();
  }
  .header-main-right {
    .flex-auto();
    .display(flex);
    .justify-content-end();
  }
  .header-main-flex {
    .display(flex);
    .flex-nowrap();
    .align-items-stretch();
    > *:not(.drop-container) {
      .align-items-stretch();
    }
    > * {
      .display(flex);
      &.showcart, &.header-search-btn, &.nav-toggle {
        .align-items-center();
      }
      > .drop-container,
      > li {
        > a, > button {
          .display(flex);
          .align-items-center();
        }
      }
    }
  }
  .header-main-desktop {
    .display(none);
    .display(flex, md);
  }
  .header-main-mobile {
    .display(none, md);
  }
  .header-main-left {
    a,
    button {
      text-transform: none !important;
    }
  }
  .drop-container,
  .header-main-flex,
  .header-main-flex > ul,
  .header-links-additional {
    > button,
    > li > a,
    > li > button {
      @media @min-md {
        .font-sans-regular() !important;
        .btn-giant() !important;
      }
      @media @min-md and @max-lg {
        padding-left: .5em !important;
        padding-right: .5em !important;
      }
      @media @min-lg {
        padding-left: 0.75em !important;
        padding-right: 0.75em !important;
      }
      > span::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 5px;
        background-color: @primary;
        .anim-flow-horizontal.out();
      }
      &:hover, &:active, &.active {
        > span::after {
          .anim-flow-horizontal.active();
        }
      }
    }
  }
  // fix horizontal spacing
  .btn-primary-empty {
    @media @max-md {
      .padding(x, none);
    }
  }
  .minicart-toggle {
    @media @min-md {
      .padding(right, none);
    }
  }
  .header-nav {
    .drop-container:first-child {
      button {
        @media @min-md and @max-lg {
          margin-left: -0.5em;
        }
        @media @min-lg {
          margin-left: -0.75em;
        }
      }
    }
  }
}

.menu-item-red {
  color: @red !important;
}

//////////////////////
// search
//////////////////////

.header-search-btn {
  > span {
    width: 16px;
    font-size: 16px;
  }
  .header-search-btn-search {
    display: inline-block;
    @media @max-md {
      width: 16px;
    }
  }
  .header-search-btn-close {
    display: none;
  }
  &.active {
    .header-search-btn-search {
      display: none;
    }
    .header-search-btn-close {
      display: block;
    }
  }
}

.header-search-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 1rem;
  display: none;
  &.active {
    display: block;
    &:before {
      content: '';
      pointer-events: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: fade(@black, 20%);
    }
  }
  .header-main-anim {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 1rem;
    background: @white;
  }
  .container {
    height: 100%;
  }
  .header-main-inner {
    height: 100%;
    .align-items-center();
  }
}

.header-search-form {
  @media @max-md {
    position: absolute;
    top: 3rem;
    left: 2rem;
    right: 6rem;
  }
  @media @max-sm {
    top: 2rem;
  }
  .input-control {
    padding: 6px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 16px;
    color: @black;
    width: 40rem;
    @media @max-md {
      width: 30rem;
    }
  }
  > .list {
    .flex-nowrap();
    .align-items-center();
    justify-content: center;
  }
}

.header-search-search {
  @media @max-md {
    display: none;
  }
}

#search_autocomplete {
  display: none !important;
}

//////////////////////
// overlays
//////////////////////

// #overlay-menu

#overlay-menu {
  // .header-mobile-head
  ul.header-mobile-head {
    margin-top: -2.5rem !important;
    @media @min-sm {
      margin-top: -4rem !important;
    }
    > li {
      button:not(.btn) {
        font-size: 17px;
        color: @primary;
        &:hover, &:active, &.active {
          color: @primary;
        }
        &:active, &.active {
          color: @primary;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          height: 3px;
          background-color: @primary;
          .anim-flow-horizontal.out();
        }
        &:hover, &:active, &.active {
          &::after {
            .anim-flow-horizontal.active();
          }
        }
        // spacing
        padding-left: 0;
        padding-right: 0;
        margin-left: 3rem;
      }
      &:first-child {
        button {
          margin-left: 0;
        }
      }
    }
  }
  // content
  .header-mobile-body {
    .padding(y, mediumbig);
  }
  .header-mobile-body-title {
    .h4-line();
    &:first-child {
      .margin(top, medium) !important;
    }
  }
}

// .header-mobile-lang

.header-mobile-lang {
  .block();
  .btn();
  .btn-primary-empty();
  .font-sans-medium();
  .btn-none-x();
  cursor: auto;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-bottom: 1px solid @grey-350 !important;
  h6 {
    .margin(top, tiny) !important;
    .reset-text();
  }
  li {
    letter-spacing: 0 !important;
    text-transform: none !important;
  }
}

// .header-content-checkout

.header-content-checkout {
  overflow: hidden;
  .header-main-flex {
    > *:not(.drop-container) {
      .align-items-center();
    }
  }
  .header-main-left .header-main-flex {
    margin-left: -1.5em;
  }
  .header-main-right .header-main-flex {
    margin-right: -1.5em;
  }
}

// .header-content-print

// .promo-banner

.promo-banner {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  background: @black;
  .font-sans-medium();
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  &.promo-banner--footer {
    @media @min-md {
      text-align: left;
    }
  }
  .inverse();
  a {
    .link-none() !important;
  }
}

.promo-banner-inner {
  position: relative;
}

.promo-banner-top {
  position: absolute;
  right: 0;
  top: 0;
  @media @max-md {
    display: none;
  }
}

.promo-banner-content {
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.promo-banner-item {
  .link-none();
  .display(flex);
  .justify-content-center();
  .align-items-center();
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media @min-md {
    white-space: nowrap;
  }
  // activation
  pointer-events: none;
  opacity: 0;
  .anim-vertical.out();
  &.active {
    position: relative;
    pointer-events: auto;
    opacity: 1;
    .anim-vertical.active();
  }
}

// .lang-switcher

.lang-switcher {
  .list();
  .justify-content-between();
  .align-items-center();
}
