//////////////////////
// document
//////////////////////

html {
  // base font size for rem
  // 100% is 16px
  // value(%) = desired(px) * 100 / 16
  font-size: 56.25%; // 9px
  @media @min-lg {
    font-size: 62.5%; // 10px
  }
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: @white;
  .font-sans-regular();
  .font-big();
  color: @text;
}

//////////////////////
// size
//////////////////////

.font-tiny {
  font-size: 10px;
  line-height: 1.4;
}

.font-small {
  font-size: 1.3rem;
  line-height: 2;
}

.font-medium {
  font-size: 1.4rem;
  line-height: 2;
}

.font-big {
  font-size: 1.5rem;
  line-height: 2.4;
}

.font-bigger {
  font-size: 1.7rem;
  line-height: 2;
}

.font-giant {
  font-size: 1.8rem;
  line-height: 2;
}

// custom

.max-width-small {
  @media @min-sm {
    max-width: 30rem;
  }
  @media @min-md {
    max-width: 31.5rem;
  }
}

//////////////////////
// headers
//////////////////////

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  .margin(y, layout) !important;
  .margin(top, medium);
  .margin(bottom, medium);
  line-height: 1.4;
  letter-spacing: 0;
  text-transform: none;
}

h1, .h1 {
  .font-sans-bold();
  font-size: 4rem;
  line-height: 1;
  @media @min-sm {
    font-size: 4.6rem;
  }
  &.small {
    font-size: 2.6rem;
    @media @min-sm {
      font-size: 3rem;
    }
  }
  .hero-subcat & {
    font-size: 3rem;
    @media @min-sm {
      font-size: 3.6rem;
    }
  }
  &.hero,
  .slider-home & {
    font-size: 3.2rem;
    @media @min-sm {
      font-size: 5rem;
    }
  }
}

h2, .h2 {
  .font-sans-semibold();
  font-size: 3.2rem;
}

h3, .h3 {
  .font-sans-semibold();
  font-size: 2.3rem;
}

h4, .h4 {
  .margin(bottom, small);
  .font-sans-semibold();
  font-size: 2rem;
  &.alt {
    .font-sans-medium();
    letter-spacing: .05em;
    text-transform: uppercase;
  }
  &.small {
    font-size: 1.8rem;
  }
}

h5, .h5 {
  .margin(bottom, small);
  .font-sans-semibold();
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h6, .h6 {
  .margin(bottom, small);
  .font-sans-medium();
  font-size: 13px;
  &.giant {
    font-size: 1.5rem;
  }
  &.big {
    font-size: 14px;
    letter-spacing: .05em;
    text-transform: uppercase;
  }
  &.small {
    font-size: 1.3rem;
    letter-spacing: .05em;
    text-transform: uppercase;
  }
}

.h-custom {
  .font-sans-regular();
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1;
  text-align: center;
}

// custom

.h4-line {
  .h4();
  .margin(bottom, medium) !important;
  .padding(bottom, small);
  border-bottom: 1px solid @grey-350;
}

.h5-block {
  .h5();
  .margin(bottom, big) !important;
  .padding(y, 1.5rem);
  .padding(x, medium);
  background-color: @grey-300;
  text-align: center;
}

.h6-line {
  .h6();
  .h6.small();
  .margin(bottom, medium) !important;
  .padding(bottom, small);
  border-bottom: 1px solid @grey-350;
}

//////////////////////
// text
//////////////////////

p {
  .margin(y, layout) !important;
  .margin(bottom, medium);
}

strong {
  .font-sans-medium();
}

small {
  font-size: .8em;
}

.muted {
  color: @black;
}

/*
// code

code {
  margin: 0 .05em;
  padding: .05em .05em;
  background-color: @grey-250;
  .font-monospace();
  font-size: .9em;
  color: @grey-750;
}

pre {
  .margin(y, layout) !important;
  .margin(y, medium);
  width: 100%;
  max-height: 37rem;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 0;
  code {
    .padding(all, medium) !important;
    width: 100%;
    background-color: @grey-250 !important;
    font-size: 12px;
    color: @grey-750;
    line-height: 1.6;
  }
}
*/

// .inverse

.inverse {
  color: @text-inverse;
  h1, h2, h3, h4, h5, h6 {
    color: @text-inverse;
  }
  code {
    background-color: fade(@white, 18%);
    color: @text-inverse;
  }
}

// reset

.reset-text {
  font-family: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  text-transform: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  line-height: inherit !important;
}

//////////////////////
// ul ol
//////////////////////

ul,
ol {
  .default-lists({
    .margin(y, layout) !important;
    .margin(bottom, medium);
    padding-left: 2rem;
    > li {
      .margin(bottom, medium);
    }
    > ul,
    > ol {
      .margin(y, medium);
    }
  });
}
ul {
  .default-lists({
    list-style: disc;
    ul {
      list-style: circle;
    }
  });
}
ol {
  .default-lists({
    list-style-type: decimal;
    ol {
      list-style-type: lower-alpha;
    }
  });
}

//////////////////////
// dl
//////////////////////

dl {
  .margin(bottom, layout);
  .margin(bottom, small);
  line-height: 1.8;
  .dl-block();
  dt {
    .font-sans-regular();
  }
  dd {
    .font-sans-regular();
  }
}

// .dl-block

.dl-block {
  .margin-remove(top, tiny);
  dt {
    .margin(top, small);
  }
  dd {
    .margin(top, tiny);
  }
}

// .dl-line

.dl-line {
  .margin-remove(top, tiny);
  dt {
    .margin(top, tiny);
  }
  dd {
    .margin(left, tiny);
    .margin(top, tiny);
  }
}

//////////////////////
// others
//////////////////////

.separator {
  .display(inline-block);
  position: relative;
  top: -.15em;
  .margin(x, tiny);
  font-size: .7em;
  vertical-align: middle;
}
.separator-bar {
  &::before {
    content: '|';
  }
}
.separator-dash {
  &::before {
    content: '–';
  }
}
.separator-arrow {
  &::before {
    content: '>';
  }
}
