& when not (@classes-util = false) {
  
  //////////////////////
  // util
  //////////////////////
  
  // generate-utils
  
  .generate-utils();
  .generate-utils(@breakpoint:none) {
    & when (@breakpoint = none) {
      // responsive
      .responsive {
        .responsive() !important;
      }
      .responsive-y {
        .responsive-y() !important;
      }
      // block
      .block {
        .block() !important;
      }
      // text-transform
      .generate-text-transform(@breakpoint);
      // text-align
      .generate-text-align(@breakpoint);
      // float
      .generate-float(@breakpoint);
      // display
      .generate-display(@breakpoint);
    }
    & when not (@breakpoint = none) {
      @query-min: 'min-@{breakpoint}';
      @media @@query-min {
        // responsive
        .responsive-@{breakpoint} {
          .responsive() !important;
        }
        .responsive-y-@{breakpoint} {
          .responsive-y() !important;
        }
        // block
        .block-@{breakpoint} {
          .block() !important;
        }
        // text-transform
        .generate-text-transform(@breakpoint);
        // text-align
        .generate-text-align(@breakpoint);
        // float
        .generate-float(@breakpoint);
        // display
        .generate-display(@breakpoint);
      }
    }
  }
  
  // generate-util-responsive
  
  & when not (@classes-util = true) {
    
    .generate-util-responsive();
    .generate-util-responsive(@i:length(@classes-util)) when (@i > 0) {
      .generate-util-responsive(@i - 1);
      @breakpoint: extract(@classes-util, @i);
      .generate-utils(@breakpoint);
    }
    
  }
  
  // generate-text-transform

  @text-transform-arr: uppercase, lowercase, capitalize, nowrap;
  
  .generate-text-transform(@breakpoint:none, @i:length(@text-transform-arr)) when (@i > 0) {
    .generate-text-transform(@breakpoint, @i - 1);
    @val: extract(@text-transform-arr, @i);
    & when (@breakpoint = none) {
      .text-transform-@{val} {
        .text-transform(@val) !important;
      }
    }
    & when not (@breakpoint = none) {
      .text-transform-@{val}-@{breakpoint} {
        .text-transform(@val) !important;
      }
    }
  }
  
  // generate-text-align
  
  @text-arr: left, right, center;
  
  .generate-text-align(@breakpoint:none, @i:length(@text-arr)) when (@i > 0) {
    .generate-text-align(@breakpoint, @i - 1);
    @val: extract(@text-arr, @i);
    & when (@breakpoint = none) {
      .text-@{val} {
        .text-align(@val) !important;
      }
    }
    & when not (@breakpoint = none) {
      .text-@{val}-@{breakpoint} {
        .text-align(@val) !important;
      }
    }
  }
  
  // generate-float
  
  @float-arr: left, right, none;
  
  .generate-float(@breakpoint:none, @i:length(@float-arr)) when (@i > 0) {
    .generate-float(@breakpoint, @i - 1);
    @val: extract(@float-arr, @i);
    & when (@breakpoint = none) {
      .float-@{val} {
        .float(@val) !important;
      }
    }
    & when not (@breakpoint = none) {
      .float-@{val}-@{breakpoint} {
        .float(@val) !important;
      }
    }
  }
  
  // generate-display
  
  @display-arr: none, block, inline, inline-block, table, table-row, table-cell, flex, inline-flex;
  
  .generate-display(@breakpoint:none, @i:length(@display-arr)) when (@i > 0) {
    .generate-display(@breakpoint, @i - 1);
    @val: extract(@display-arr, @i);
    & when (@breakpoint = none) {
      .display-@{val} {
        .display(@val) !important;
      }
    }
    & when not (@breakpoint = none) {
      .display-@{val}-@{breakpoint} {
        .display(@val) !important;
      }
    }
  }
  
  //////////////////////
  
}

& when not (@classes-margin = false) {
  
  //////////////////////
  // margin
  //////////////////////
  
  @margin-arr: none, y, x, t, b, l, r;
  
  // layout
  
  .m-y-layout {
    .margin(y, layout) !important;
  }
  .m-x-layout {
    .margin(x, layout) !important;
  }
  .m-top-layout {
    .margin(top, layout) !important;
  }
  .m-bottom-layout {
    .margin(bottom, layout) !important;
  }
  .m-left-layout {
    .margin(left, layout) !important;
  }
  .m-right-layout {
    .margin(right, layout) !important;
  }
  
  // generate-margin
  
  .generate-margins();
  .generate-margins(@breakpoint:none) {
    & when (@breakpoint = none) {
      .generate-margin(@breakpoint);
    }
    & when not (@breakpoint = none) {
      @query-min: 'min-@{breakpoint}';
      @media @@query-min {
        .generate-margin(@breakpoint);
      }
    }
  }
  
  // generate-margins
  
  .generate-margin(@breakpoint:none, @i:length(@margin-arr)) when (@i > 0) {
    .generate-margin(@breakpoint, @i - 1);
    @pos: extract(@margin-arr, @i);
    .generate-margin-loop(@pos, @breakpoint);
  }
  .generate-margin-loop(@pos, @breakpoint, @i:length(@margins)) when (@i > 0) {
    .generate-margin-loop(@pos, @breakpoint, @i - 1);
    @margin: extract(@margins, @i);
    @name: extract(@margin, 1);
    @size: extract(@margin, 2);
    & when (@breakpoint = none) {
      & when (@pos = none) {
        .m-@{name} {
          .margin(@pos, @name, @breakpoint) !important;
        }
        & when not (@size = auto) and not (@size = 0) {
          .m@{name}-remove {
            .margin-remove(@pos, @name, @breakpoint) !important;
          }
        }
      }
      & when not (@pos = none) {
        .m@{pos}-@{name} {
          .margin(@pos, @name, @breakpoint) !important;
        }
        & when not (@size = auto) and not (@size = 0) {
          .m@{pos}-@{name}-remove {
            .margin-remove(@pos, @name, @breakpoint) !important;
          }
        }
      }
    }
    & when not (@breakpoint = none) {
      & when (@pos = none) {
        .m-@{name}-@{breakpoint} {
          .margin(@pos, @name) !important;
        }
        & when not (@size = auto) and not (@size = 0) {
          .m-@{name}-@{breakpoint}-remove {
            .margin-remove(@pos, @name) !important;
          }
        }
      }
      & when not (@pos = none) {
        .m@{pos}-@{name}-@{breakpoint} {
          .margin(@pos, @name) !important;
        }
        & when not (@size = auto) and not (@size = 0) {
          .m@{pos}-@{name}-@{breakpoint}-remove {
            .margin-remove(@pos, @name) !important;
          }
        }
      }
    }
  }
  
  // generate-margin-responsive
  
  & when not (@classes-margin = true) {
    
    .generate-margin-responsive();
    .generate-margin-responsive(@i:length(@classes-margin)) when (@i > 0) {
      .generate-margin-responsive(@i - 1);
      @breakpoint: extract(@classes-margin, @i);
      .generate-margins(@breakpoint);
    }
    
  }
  
  //////////////////////
  
}

& when not (@classes-padding = false) {
  
  //////////////////////
  // padding
  //////////////////////
  
  @padding-arr: none, y, x, t, b, l, r;
  
  // layout
  
  .p-y-layout {
    .padding(y, layout) !important;
  }
  .p-x-layout {
    .padding(x, layout) !important;
  }
  .p-top-layout {
    .padding(top, layout) !important;
  }
  .p-bottom-layout {
    .padding(bottom, layout) !important;
  }
  .p-left-layout {
    .padding(left, layout) !important;
  }
  .p-right-layout {
    .padding(right, layout) !important;
  }
  
  // generate-padding
  
  .generate-paddings();
  .generate-paddings(@breakpoint:none) {
    & when (@breakpoint = none) {
      .generate-padding(@breakpoint);
    }
    & when not (@breakpoint = none) {
      @query-min: 'min-@{breakpoint}';
      @media @@query-min {
        .generate-padding(@breakpoint);
      }
    }
  }
  .generate-padding(@breakpoint:none, @i:length(@padding-arr)) when (@i > 0) {
    .generate-padding(@breakpoint, @i - 1);
    @pos: extract(@padding-arr, @i);
    .generate-padding-loop(@pos, @breakpoint);
  }
  .generate-padding-loop(@pos, @breakpoint, @i:length(@paddings)) when (@i > 0) {
    .generate-padding-loop(@pos, @breakpoint, @i - 1);
    @padding: extract(@paddings, @i);
    @name: extract(@padding, 1);
    @size: extract(@padding, 2);
    & when (@breakpoint = none) {
      & when (@pos = none) {
        .p-@{name} {
          .padding(@pos, @name, @breakpoint) !important;
        }
      }
      & when not (@pos = none) {
        .p@{pos}-@{name} {
          .padding(@pos, @name, @breakpoint) !important;
        }
      }
    }
    & when not (@breakpoint = none) {
      & when (@pos = none) {
        .p-@{name}-@{breakpoint} {
          .padding(@pos, @name) !important;
        }
      }
      & when not (@pos = none) {
        .p@{pos}-@{name}-@{breakpoint} {
          .padding(@pos, @name) !important;
        }
      }
    }
  }
  
  // generate-padding-responsive
  
  & when not (@classes-padding = true) {
    
    .generate-padding-responsive();
    .generate-padding-responsive(@i:length(@classes-padding)) when (@i > 0) {
      .generate-padding-responsive(@i - 1);
      @breakpoint: extract(@classes-padding, @i);
      .generate-paddings(@breakpoint);
    }
    
  }
  
  //////////////////////
  
}

& when not (@classes-layout = false) {
  
  //////////////////////
  // layout
  //////////////////////
  
  // generate-layouts
  
  .generate-layouts();
  .generate-layouts(@breakpoint:none) {
    & when (@breakpoint = none) {
      // list alignment
      .list-left {
        .list-left() !important;
      }
      .list-center {
        .list-center() !important;
      }
      .list-right {
        .list-right() !important;
      }
      // container
      .generate-container(@breakpoint);
      // grid
      .generate-grid(@breakpoint);
      .col-auto {
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: 100%; // fix ie10 overflow
        flex-basis: auto;
        max-width: none;
        width: auto;
      }
      // order
      .generate-order(@breakpoint);
      // flex-space
      .generate-flex-space(@breakpoint);
      // list-space
      .generate-list-space(@breakpoint);
      // flex util
      .generate-flex-util(@breakpoint);
    }
    & when not (@breakpoint = none) {
      @query-min: 'min-@{breakpoint}';
      @media @@query-min {
        // list alignment
        .list-left-@{breakpoint} {
          .list-left() !important;
        }
        .list-center-@{breakpoint} {
          .list-center() !important;
        }
        .list-right-@{breakpoint} {
          .list-right() !important;
        }
        // container
        .generate-container(@breakpoint);
        // grid
        .generate-grid(@breakpoint);
        .col-auto-@{breakpoint} {
          -webkit-flex-basis: auto;
          -ms-flex-preferred-size: 100%; // fix ie10 overflow
          flex-basis: auto;
          max-width: none;
          width: auto;
        }
        // order
        .generate-order(@breakpoint);
        // flex-space
        .generate-flex-space(@breakpoint);
        // list-space
        .generate-list-space(@breakpoint);
        // flex util
        .generate-flex-util(@breakpoint);
      }
    }
  }
  
  // generate-layout-responsive
  
  & when not (@classes-layout = true) {
    
    .generate-layout-responsive();
    .generate-layout-responsive(@i:length(@classes-layout)) when (@i > 0) {
      .generate-layout-responsive(@i - 1);
      @breakpoint: extract(@classes-layout, @i);
      .generate-layouts(@breakpoint);
    }
    
  }
  
  // generate-container
  
  .generate-container(@breakpoint:none, @i:length(@container-space)) when (@i > 0) {
    .generate-container(@breakpoint, @i - 1);
    @container: extract(@container-space, @i);
    @break: extract(@container, 1);
    @val: extract(@container, 2);
    & when (@breakpoint = @break) {
      .container {
        padding-right: @val;
        padding-left: @val;
      }
    }
  }
  
  // generate-grid
  
  .generate-grid(@breakpoint; @i:1) when (@i =< @columns) {
    .generate-grid(@breakpoint; (@i + 1));
    & when (@breakpoint = none) {
      .col-@{i} {
        .col(@i);
      }
    }
    & when not (@breakpoint = none) {
      .col-@{i}-@{breakpoint} {
        .col(@i);
      }
    }
  }
  
  // generate-order
  
  .generate-order(@breakpoint:none, @i:length(@orders)) when (@i > 0) {
    .generate-order(@breakpoint, @i - 1);
    @val: extract(@orders, @i);
    & when (@breakpoint = none) {
      .order-@{val} {
        .order(@val) !important;
      }
    }
    & when not (@breakpoint = none) {
      .order-@{val}-@{breakpoint} {
        .order(@val) !important;
      }
    }
  }
  
  // generate-flex-space
  
  .generate-flex-space(@breakpoint:none, @i:length(@flex-spaces)) when (@i > 0) {
    .generate-flex-space(@breakpoint, @i - 1);
    @name: extract(@flex-spaces, @i);
    & when (@breakpoint = none) {
      .flex-space-y-@{name} {
        .flex-space-y(@name) !important;
      }
      .flex-space-x-@{name} {
        .flex-space-x(@name) !important;
      }
      .flex-space-@{name} {
        .flex-space(@name) !important;
      }
    }
    & when not (@breakpoint = none) {
      .flex-space-y-@{name}-@{breakpoint} {
        .flex-space-y(@name) !important;
      }
      .flex-space-x-@{name}-@{breakpoint} {
        .flex-space-x(@name) !important;
      }
      .flex-space-@{name}-@{breakpoint} {
        .flex-space(@name) !important;
      }
    }
  }
  
  // generate-list-space
  
  .generate-list-space(@breakpoint:none, @i:length(@list-spaces)) when (@i > 0) {
    .generate-list-space(@breakpoint, @i - 1);
    @name: extract(@list-spaces, @i);
    & when (@breakpoint = none) {
      .list-space-y-@{name} {
        .list-space-y(@name) !important;
      }
      .list-space-x-@{name} {
        .list-space-x(@name) !important;
      }
      .list-space-@{name} {
        .list-space(@name) !important;
      }
    }
    & when not (@breakpoint = none) {
      .list-space-y-@{name}-@{breakpoint} {
        .list-space-y(@name) !important;
      }
      .list-space-x-@{name}-@{breakpoint} {
        .list-space-x(@name) !important;
      }
      .list-space-@{name}-@{breakpoint} {
        .list-space(@name) !important;
      }
    }
  }
  
  // .generate-flex-util
  
  .generate-flex-util(@breakpoint:none) {
    & when (@breakpoint = none) {
      .flex-auto {
        .flex-auto() !important;
      }
      .flex-row {
        .flex-row() !important;
      }
      .flex-row-reverse {
        .flex-row-reverse() !important;
      }
      .flex-column {
        .flex-column() !important;
      }
      .flex-column-reverse {
        .flex-column-reverse() !important;
      }
      .flex-wrap {
        .flex-wrap() !important;
      }
      .flex-wrap-reverse {
        .flex-wrap-reverse() !important;
      }
      .flex-nowrap {
        .flex-nowrap() !important;
      }
      .justify-content-start {
        .justify-content-start() !important;
      }
      .justify-content-end {
        .justify-content-end() !important;
      }
      .justify-content-center {
        .justify-content-center() !important;
      }
      .justify-content-between {
        .justify-content-between() !important;
      }
      .justify-content-around {
        .justify-content-around() !important;
      }
      .align-items-start {
        .align-items-start() !important;
      }
      .align-items-end {
        .align-items-end() !important;
      }
      .align-items-center {
        .align-items-center() !important;
      }
      .align-items-baseline {
        .align-items-baseline() !important;
      }
      .align-items-stretch {
        .align-items-stretch() !important;
      }
      .align-content-start {
        .align-content-start() !important;
      }
      .align-content-end {
        .align-content-end() !important;
      }
      .align-content-center {
        .align-content-center() !important;
      }
      .align-content-between {
        .align-content-between() !important;
      }
      .align-content-around {
        .align-content-around() !important;
      }
      .align-content-stretch {
        .align-content-stretch() !important;
      }
      .align-self-start {
        .align-self-start() !important;
      }
      .align-self-end {
        .align-self-end() !important;
      }
      .align-self-center {
        .align-self-center() !important;
      }
      .align-self-baseline {
        .align-self-baseline() !important;
      }
      .align-self-stretch {
        .align-self-stretch() !important;
      }
      .align-self-auto {
        .align-self-auto() !important;
      }
    }
    & when not (@breakpoint = none) {
      .flex-auto-@{breakpoint} {
        .flex-auto() !important;
      }
      .flex-row-@{breakpoint} {
        .flex-row() !important;
      }
      .flex-row-reverse-@{breakpoint} {
        .flex-row-reverse() !important;
      }
      .flex-column-@{breakpoint} {
        .flex-column() !important;
      }
      .flex-column-reverse-@{breakpoint} {
        .flex-column-reverse() !important;
      }
      .flex-wrap-@{breakpoint} {
        .flex-wrap() !important;
      }
      .flex-wrap-reverse-@{breakpoint} {
        .flex-wrap-reverse() !important;
      }
      .flex-nowrap-@{breakpoint} {
        .flex-nowrap() !important;
      }
      .justify-content-start-@{breakpoint} {
        .justify-content-start() !important;
      }
      .justify-content-end-@{breakpoint} {
        .justify-content-end() !important;
      }
      .justify-content-center-@{breakpoint} {
        .justify-content-center() !important;
      }
      .justify-content-between-@{breakpoint} {
        .justify-content-between() !important;
      }
      .justify-content-around-@{breakpoint} {
        .justify-content-around() !important;
      }
      .align-items-start-@{breakpoint} {
        .align-items-start() !important;
      }
      .align-items-end-@{breakpoint} {
        .align-items-end() !important;
      }
      .align-items-center-@{breakpoint} {
        .align-items-center() !important;
      }
      .align-items-baseline-@{breakpoint} {
        .align-items-baseline() !important;
      }
      .align-items-stretch-@{breakpoint} {
        .align-items-stretch() !important;
      }
      .align-content-start-@{breakpoint} {
        .align-content-start() !important;
      }
      .align-content-end-@{breakpoint} {
        .align-content-end() !important;
      }
      .align-content-center-@{breakpoint} {
        .align-content-center() !important;
      }
      .align-content-between-@{breakpoint} {
        .align-content-between() !important;
      }
      .align-content-around-@{breakpoint} {
        .align-content-around() !important;
      }
      .align-content-stretch-@{breakpoint} {
        .align-content-stretch() !important;
      }
      .align-self-auto-@{breakpoint} {
        .align-self-auto() !important;
      }
      .align-self-start-@{breakpoint} {
        .align-self-start() !important;
      }
      .align-self-end-@{breakpoint} {
        .align-self-end() !important;
      }
      .align-self-center-@{breakpoint} {
        .align-self-center() !important;
      }
      .align-self-baseline-@{breakpoint} {
        .align-self-baseline() !important;
      }
      .align-self-stretch-@{breakpoint} {
        .align-self-stretch() !important;
      }
    }
  }
  
  //////////////////////
  
}