//////////////////////
// icon
//////////////////////

// size

.icon-tiny {
  font-size: @icon-tiny;
}

.icon-small {
  font-size: @icon-small;
}

.icon-medium {
  font-size: @icon-medium;
}

.icon-big {
  overflow: hidden;
  .margin(y, -.25em);
  font-size: @icon-big;
}

.icon-giant {
  overflow: hidden;
  .margin(y, -.5em);
  font-size: @icon-giant;
}

// space

.icon-left {
  .margin(right, .75em);
}

.icon-right {
  .margin(left, .75em);
}
