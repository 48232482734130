//////////////////////
// tables
//////////////////////

@table-border-width: 1px;

.table, .table-scroll {
  .margin(y, layout) !important;
  .margin(bottom, medium);
}

// .table

.table {
  .font-small();
  line-height: 1.25;
  tr {
    border-bottom: @table-border-width solid @grey-300;
  }
  th {
    .padding(all, 1.5rem);
    .h6();
    color: @black;
  }
  td {
    .padding(all, 1.5rem);
  }
  // layout
  &:not(.table-stripe):not(.table-border):not(.table-center) {
    th, td {
      .padding(x, layout);
    }
  }
  // data-th for .table-responsive
  td::before {
    .label-control();
  }
  // tfoot
  tfoot {
    .padding(y, medium) !important;
    background-color: @grey-250;
    th {
      .reset-text();
    }
    tr {
      border: 0;
      th,
      td {
        .padding(y, tiny) !important;
        .padding(x, medium) !important;
        text-align: right;
        vertical-align: top;
      }
      &:first-child {
        th,
        td {
          .padding(top, medium) !important;
        }
      }
      &:last-child {
        th,
        td {
          .padding(bottom, medium) !important;
        }
      }
    }
  }
}

// .table-small

.table-small {
  th {
    .padding(y, small);
  }
  td {
    .padding(y, tiny);
    .font-small();
  }
}

// .table-stripe

.table-stripe {
  tbody tr:nth-of-type(odd) {
    background-color: @grey-250;
  }
  tbody tr:nth-of-type(even) {
    background-color: transparent;
  }
}

// .table-border

.table-border {
  border: @table-border-width solid @grey-300;
  th,
  td {
    border: @table-border-width solid @grey-300;
  }
}

// .table-responsive

.table-responsive {
  @media @max-sm {
    border: @table-border-width solid darken(@grey-300, @perc-xs);
    border-bottom: 0;
    thead, th {
      display: none;
    }
    
    tr {
      .block();
      .padding(y, big);
    }
    
    td {
      .block();
      .padding(x, medium) !important;
      .padding(y, medium);
      text-align: center;
    }
    
    // data-th
    td::before {
      content: attr(data-th);
      width: 100%;
      .justify-content-center();
    }
    
    // center label and input
    .label-control {
      width: 100%;
      .justify-content-center();
    }
    
    .input-group {
      .justify-content-center();
    }
  }
}

// custom

.box-grey {
  table.table {
    &,
    tr,
    tr th,
    tr td {
      border-color: darken(@grey-300, @perc-xs) !important;
    }
  }
}
