//////////////////////
// button
//////////////////////

button,
.action.primary {
  &:extend(.btn all);
  .btn-primary();
  &.abs-button-s {
    .btn-small();
  }
  &.abs-button-l {
    .btn-big();
  }
  &.alink {
    .btn-link();
  }
  &.abs-button-responsive {
    .block();
    .text-align(center);
  }
}

.action.secondary,
.action-secondary {
  .btn-secondary();
}

//////////////////////
// iubenda
//////////////////////

.iubenda-tp-btn {
  &:before, &:after {
    display: none;
  }
}

#swagger-ui,
#iubenda-cs-banner, #iubenda-iframe-popup {
  button {
    &:before, &:after {
      display: none;
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    & ~ label {
      color: inherit;
    }
  }
}

//////////////////////
// drop
//////////////////////

.ui-dialog-container {
  &:extend(.drop-container all);
  &.active {
    .ui-dialog {
      &:extend(.drop-container.active > .drop all);
    }
  }
}

.ui-dialog {
  &:extend(.drop all);
  .ui-dialog-content {
    display: block;
    &:extend(.drop > .drop-inner all);
  }
}

.ui-dialog-content > ul, ul.dropdown { // ul.dropdown for store/switch
  .list();
  .list-drop();
  &:extend(.drop > .drop-inner > .list-drop all);
  .drop > .drop-inner > .list() !important;
}

// @FIX toggle

.ui-dialog {
  display: block;
}

// @FIX hide before mage-init

[data-role="dropdownDialog"] {
  display: none;
}

//////////////////////
// overlay
//////////////////////

.modal-custom:not(.opc-sidebar),
.modal-popup {
  &:extend(.overlay-outer all);
  .modal-outer-wrap {
    &:extend(.overlay all);
  }
  .modal-inner-wrap {
    &:extend(.overlay-inner all);
    &:extend(.overlay .overlay-inner all);
  }
  .action-close {
    .overlay .overlay-inner > .btn-close();
  }
  .modals-overlay {
    &:extend(.xt-backdrop all);
    &:extend(.overlay-outer .xt-backdrop all);
  }
  .modal-header, .modal-title {
    .h3();
  }
  &._show {
    &:extend(.overlay-outer.active all);
    .modal-inner-wrap {
      &:extend(.overlay-outer.active .overlay-inner all);
    }
    .modals-overlay {
      &:extend(.xt-backdrop.active all);
    }
  }
}

body._has-modal {
  overflow-y: hidden;
  .modal-popup._show {
    overflow-y: scroll;
  }
}

//////////////////////
// tab
//////////////////////

[role="tablist"] {
  .list();
  .list-space(none);
}

// @FIX size

[role="tablist"],
[role="tabpanel"],
[data-role="collapsible"] {
  width: 100%;
}

// @FIX hide before mage-init

[data-role="collapsible"] {
  [data-role="content"], & + [data-role="content"] {
    &:not([aria-hidden="false"]) {
      .display(none) !important;
    }
  }
}

// @FIX hide before mage-init
// took from magento

.block.shipping:not(.active) .content,
.block.discount:not(.active) .content {
    display: none;
}

//////////////////////
// forms
//////////////////////

.field {
  &:extend(.form-group all);
}

label {
  &:extend(.label-control all);
}

.input-text,
.swatch-input,
select,
textarea,
.limiter-options,
.sorter-options {
  &:extend(.input-control all);
}

input[type="checkbox"],
input[type="radio"] {
  &:extend(.input-special all);
  & + label {
    &:extend(.label-special all);
  }
}

caption {
  display: none !important;
}

// @FIX nested fields

.field .field {
  .padding(all, none) !important;
  .padding(top, 1em) !important;
}

// @FIX select arrow

select {
  &:extend(select.input-control all);
}

// @FIX .control

.control {
  width: 100%;
}

.form-group-inline {
  .control {
    width: auto;
  }
}

// @FIX .control inside .input-group

.input-group {
  .control, .control .input-control {
    height: 100%;
  }
}

// @FIX legend used as label

legend.label {
  &:extend(.label-control all);
  margin: 0;
}

// @FIX remove br

legend + br {
  display: none !important;
}

// @FIX field used in weird places

.field {
  dd & {
    margin: 0 !important;
  }
}

// @FIX span used in weird places

.label-special {
  > span:not(.price) {
    .margin(left, none);
  }
}

//////////////////////
// various
//////////////////////

.action.clear,
.action.remind,
.action.order,
.action.print,
.action.back {
  .btn();
  .btn-secondary();
}
.action.skip {
  .sr-only();
}
.action.close,
.action-close {
  .btn-close();
  .box > .btn-close();
}

.no-display {
  .display(none) !important;
}

.actions-toolbar {
  > .action {
    .order(0);
  }
  > .secondary {
    .order(1);
  }
  > .primary {
    .order(2);
  }
}

.password-strength-meter {
  .note();
}
