//////////////////////
// category filters
//////////////////////

// .filter

.filter {
  .filter-current,
  .block-subtitle,
  .count {
    .display(none) !important;
  }
  .filter-content {
    .btn-caret::after {
      margin-left: 0;
      margin-right: 2em;
    }
    .btn-current {
      margin-left: .6em;
      .font-sans-regular();
    }
  }
  .filter-content-outer {
    .btn-primary-empty {
      white-space: nowrap;
    }
    @media @max-sm {
      .filter-content {
        .drop-container {
          width: 100%;
        }
      }
      .filter-content-inner {
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
        .btn-primary-empty {
          .block();
          .btn-short();
          .justify-content-start();
          width: 100%;
        }
        .drop-container > .xt-backdrop {
          display: none !important;
        }
      }
    }
    @media @min-sm {
      .drop-disable();
      .filter-content-inner {
        .list-space(big) !important;
        padding: 0 !important;
      }
      > .xt-backdrop {
        display: none !important;
      }
    }
    /*
    .btn-text {
      max-width: 12rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    */
  }
}

#aw-layered-nav-popup,
#layered-selected-filters-block {
  display: none !important;
}

.filter-content .drop-inner [data-role="content"] {
  input {
    display: none !important;
  }
  button {
    .font-sans-regular() !important;
    &.active {
      .font-sans-semibold() !important;
    }
  }
}