//////////////////////
// link
//////////////////////

// break-word

a, .alink, .btn-link {
  .user-select-none();
  overflow-wrap: break-word;
  word-wrap: break-word;
}

// disabled

[disabled], .disabled {
  cursor: default;
  pointer-events: none;
}

//////////////////////
// btn
//////////////////////

.btn {
  .user-select-none();
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  z-index: @index-base; // fix ::before negative z-index
  &:active, &.active {
    z-index: @index-active;
  }
  &.out {
    z-index: @index-active - 1;
  }
  > span {
    display: inline-block;
    vertical-align: middle;
  }
}

// custom

.btn-close {
  z-index: @index-btn-close;
}

//////////////////////
// drop
//////////////////////

.drop-container {
  display: inline-block;
  position: relative;
  &.active {
    &, & > .drop {
      z-index: @index-drop;
    }
  }
  &.out {
    &, & > .drop {
      z-index: @index-drop - 1;
    }
  }
  > .xt-backdrop, > .drop {
    .display(none);
  }
  &.active, &.out {
    > .xt-backdrop, > .drop {
      .display(block);
    }
  }
}

.drop {
  // styles
  position: absolute;
  > .drop-inner {
    position: relative;
    z-index: @index-base; // fix ::before negative z-index
  }
  // others
  &.out {
    pointer-events: none;
  }
}

// addons

.drop-container-static {
  position: static;
  > .drop,
  > .drop > .drop-inner {
    width: 100%;
  }
}

// before + after

.drop-before() {
  padding: @drop-space;
  left: auto;
  right: 100%;
}

.drop.drop-before {
  .drop-before() !important;
}

.drop-after() {
  padding: @drop-space;
  left: 100%;
  right: auto;
}

.drop.drop-after {
  .drop-after() !important;
}

// left + right

.drop-left() {
  padding: @drop-space 0;
  left: 0;
  right: auto;
}

.drop.drop-left {
  .drop-left() !important;
}

.drop-right() {
  padding: @drop-space 0;
  left: auto;
  right: 0;
}

.drop.drop-right {
  .drop-right() !important;
}

// bottom + top

.drop-bottom() {
  padding: @drop-space 0;
  top: 100%;
  bottom: auto;
}

.drop.drop-bottom {
  .drop-bottom() !important;
}

.drop-top() {
  padding: @drop-space 0;
  top: auto;
  bottom: 100%;
}

.drop.drop-top {
  .drop-top() !important;
}

// middle

.drop-middle() {
  padding-top: 0;
  padding-bottom: 0;
}

.drop.drop-middle {
  .drop-middle() !important;
}

.drop-middle-bottom() {
  top: auto;
  bottom: 0;
}

.drop.drop-middle-bottom {
  .drop-middle-bottom() !important;
}

.drop-middle-top() {
  top: 0;
  bottom: auto;
}

.drop.drop-middle-top {
  .drop-middle-top() !important;
}

// .drop-disable

.drop-disable {
  position: static;
  z-index: initial;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  &, > .drop {
    animation-duration: inherit;
  }
  > a, > button {
    .display(none) !important;
  }
  > .drop {
    .display(block) !important;
    position: relative;
    > .drop-inner {
      z-index: initial;
      min-width: auto;
      &::before {
        display: none;
      }
    }
  }
}

//////////////////////
// overlay
//////////////////////

.overlay-outer {
  .toggle-flex();
  position: fixed;
  z-index: @index-overlay;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // scroll
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.overlay {
  .display(flex);
  .flex-column();
  .flex-nowrap();
  .justify-content-center();
  margin: auto; // @FIX http://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
  width: 100%;
  min-height: 100vh;
}

.overlay-inner {
  position: relative;
  z-index: @index-overlay;
  width: 100%;
  .align-self-center();
  > .btn-close + * {
    .margin(top, none) !important;
  }
}

// addons

.overlay-screen {
  .overlay {
    position: relative;
    z-index: @index-overlay;
    max-width: none !important;
    .overlay-inner {
      position: static;
      .padding(all, none) !important;
    }
  }
  .xt-backdrop {
    opacity: 0 !important;
    animation: none !important;
  }
}

.overlay-pos {
  .overlay {
    position: relative;
    z-index: @index-overlay;
    .justify-content-start();
    .overlay-inner {
      position: static;
      .padding(all, none) !important;
    }
  }
  .overlay-left& {
    .overlay {
      margin-left: 0;
      margin-right: auto;
    }
  }
  .overlay-right& {
    .overlay {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

//////////////////////
// preload
//////////////////////

.preload-container {
  position: absolute;
  z-index: @index-loader;
  top: 0;
  left: 0;
}

.preload {
  position: relative;
  width: 100%;
}

// addons

.preload-container {
  width: 100%;
}

.preload-full {
  .display(flex);
  .align-items-center();
  .justify-content-center();
  height: 100%;
}

.preload-relative {
  position: relative;
}

.preload-fixed {
  position: fixed;
}

.preload-html {
  html.preloaded & {
    display: none;
  }
}

//////////////////////
// plugins
//////////////////////

// .xt-backdrop

.xt-backdrop {
  position: fixed;
  z-index: @index-backdrop;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
