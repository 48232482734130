//////////////////////
// icon
//////////////////////

.xt-icon {
  display: inline-block;
  position: relative;
  font-family: @icons-family !important;
  font-size: @icon-medium;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: inherit;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='xt-icon-'], [class*=' xt-icon-'] {
  &::before {
    .xt-icon();
  }
}

// icon ::before or ::after

.icon(@content, @size: @icon-medium, @sr-only: false, @position: after) {
  & when (@sr-only = true) {
    > span {
      .sr-only();
    }
  }
  & when (@position = before) {
    &::before {
      .xt-icon();
      content: @content;
      font-size: @size;
    }
  }
  & when (@position = after) {
    &::after {
      .xt-icon();
      content: @content;
      font-size: @size;
    }
  }
}

// .generate-icons

& when (@classes-icons) {
  
  .generate-icons();
  .generate-icons(@i:length(@icons)) when (@i > 0) {
    .generate-icons(@i - 1);
    @name: extract(@icons, @i);
    @val: ~"icon-@{name}";
    .xt-icon-@{name} {
      &::before {
        content: @@val;
      }
    }
  }
  
}