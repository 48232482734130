//////////////////////
// toggle
//////////////////////

.toggle-block {
  .display(none) !important;
  &.active, &.out {
    .display(block) !important;
  }
}

.toggle-flex {
  .display(none) !important;
  &.active, &.out {
    .display(flex) !important;
  }
}

.toggle-inline-flex {
  .display(none) !important;
  &.active, &.out {
    .display(inline-flex) !important;
  }
}

.toggle-inline {
  .display(none) !important;
  &.active, &.out {
    .display(inline) !important;
  }
}

.toggle-inline-block {
  .display(none) !important;
  &.active, &.out {
    .display(inline-block) !important;
  }
}

.toggle-none {
  &.active, &.out {
    .display(none) !important;
  }
}

.toggle-visible {
  visibility: hidden !important;
  &.active, &.out {
    visibility: visible !important;
  }
}

.toggle-hidden {
  &.active, &.out {
    visibility: hidden !important;
  }
}

//////////////////////
// utils
//////////////////////

// .xt-container

.xt-container {
  position: relative;
  width: 100%;
}

// .xt-scrollbar

html.xt-scrollbar {
  body {
    overflow-y: hidden;
  }
}

//////////////////////
// xt-sticky
//////////////////////

/**
 * .xt-sticky()
 * @param {String} @mode Starting mode be 'absolute' or 'fixed' or 'fixed-always'
 */
.xt-sticky(@mode: none) {
  // defaults
  &.xt-clone {
    position: relative;
    visibility: hidden;
    top: 0;
  }
  &:not(.xt-clone) {
    position: relative;
    z-index: @index-sticky;
    width: 100%;
  }
  // normal mode
  & when (@mode = none) {
    &.xt-clone:not(.active) {
      position: absolute;
    }
    .xt-container > &:not(.xt-clone):not(.active) {
      position: relative;
    }
  }
  // absolute mode
  & when (@mode = 'absolute') {
    &.xt-clone:not(.active) {
      position: relative;
    }
    .xt-container > &:not(.xt-clone):not(.active) {
      position: absolute;
    }
  }
  // fixed mode
  & when (@mode = 'fixed') {
    &.xt-clone:not(.active) {
      position: relative;
    }
    .xt-container > &:not(.xt-clone):not(.active) {
      position: fixed;
    }
  }
  // fixed-always mode
  & when (@mode = 'fixed-always') {
    &.xt-clone:not(.active) {
      position: relative;
    }
    &:not(.xt-clone):not(.active) {
      position: fixed !important;
    }
  }
  // showhide
  .show-sticky {
    display: none;
  }
  .hide-sticky {
    display: block;
  }
  &:not(.xt-clone).active {
    .show-sticky {
      display: block;
    }
    .hide-sticky {
      display: none;
    }
  }
  // activation
  &:not(.xt-clone).active {
    position: fixed;
    z-index: @index-sticky;
    width: 100%;
  }
}

.xt-sticky-disable {
  position: relative !important;
  top: auto !important;
  bottom: auto !important;
  &.xt-clone {
    position: absolute !important;
  }
  &:not(.xt-clone) {
    position: relative !important;
  }
}