//////////////////////
// product
//////////////////////

// .product-info-main

.product-info-main {
  .padding(top, big, sm);
  .padding(top, huge, lg);
  .padding(bottom, medium, sm);
  @media @max-sm {
    text-align: center;
  }
  .page-title-wrapper {
    .margin(bottom, small);
    @media @min-sm {
      .margin(top, medium);
    }
    .page-title {
      .h4();
    }
  }
  .price-box {
    .margin(bottom, small);
  }
  .product-options-wrapper {
    .super-attribute-drop-container {
      .drop-inner {
        min-width: 15rem;
      }
    }
    .super-attribute-select {
      visibility: hidden;
      padding: 0;
      height: 0;
      border: 0;
    }
    .super-attribute-select + .mage-error {
      visibility: hidden;
      padding: 0;
      height: 0;
      border: 0;
    }
  }
  .overview,
  .description {
    margin-top: 2.5rem;
    line-height: 2;
  }
  .box-tocart {
    .margin(top, medium);
    .field.qty {
      .display(none);
    }
    .action.tocart {
      .btn-primary-border();
      @media @max-sm {
        .btn-big();
      }
    }
  }
}

// .product-shipping

.product-shipping {
  @media @max-sm {
    display: none;
  }
  .margin(top, mediumbig);
  line-height: 1.8;
  .product-shipping-item {
    .flex();
    .flex-space(small);
    .flex-nowrap();
    .align-items-center();
  }
  .product-shipping-item-content {
    .flex();
    .flex-space(small);
    .align-items-center();
    line-height: 1;
  }
  .product-shipping-icon {
    width: 35px;
    font-size: 18px;
    > * {
      .icon-medium();
    }
  }
  .product-shipping-title {
    .font-sans-regular();
    font-size: 12px;
  }
  .product-shipping-subtitle {
    .font-sans-regular();
    font-size: 12px;
  }
}

// .product-group-content

.product-group-content {
  text-align: center;
  .product.description {
    .margin(x, auto);
    max-width: 60rem;
  }
  .product-share {
    .margin(top, medium);
  }
}

// .product-related

.block.related {
  .margin(top, huge);
}
