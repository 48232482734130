//////////////////////
// flex
//////////////////////

.flex {
  // options
  .flex-space(medium); // space
  .flex-row(); // direction
  .flex-wrap(); // wrap
}

//////////////////////
// list
//////////////////////

.list {
  // options
  .list-space(small); // space
  .list-left(); // alignment
  .list-primary-border(); // variant
}

.list-block {
  // options
  .list-space(small); // space
  .list-primary-border(); // variant
}

// components

.list-drop {
  .flex-column();
  .flex-nowrap();
  .list-space(none); // space
  .list-small(); // size
  .list-short();
}

// variant

.list-primary {
  .list-btn-default({ .btn-primary() });
}

.list-primary-empty {
  .list-btn-default({ .btn-primary-empty() });
}

.list-primary-border {
  .list-btn-default({ .btn-primary-border() });
}

.list-link {
  .list-btn-default({ .btn-link() });
}

// size

.list-tiny {
  .list-btn-default({ .btn-tiny() });
}

.list-small {
  .list-btn-default({ .btn-small() });
}

.list-medium {
  .list-btn-default({ .btn-medium() });
}

.list-big {
  .list-btn-default({ .btn-big() });
}

.list-giant {
  .list-btn-default({ .btn-giant() });
}

// special

.list-nodesign {
  .list-btn-default({ .btn-nodesign() });
}

.list-squared {
  .list-btn-default({ .btn-squared() });
}

.list-tall {
  .list-btn-default({ .btn-tall() });
}

.list-short {
  .list-btn-default({ .btn-short() });
}

.list-wide {
  .list-btn-default({ .btn-wide() });
}

.list-narrow {
  .list-btn-default({ .btn-narrow() });
}

.list-icon {
  .list-btn-default({ .btn-icon() });
}

.list-none {
  .list-btn-default({ .btn-none() });
}

.list-none-x {
  .list-btn-default({ .btn-none-x() });
}

.list-none-y {
  .list-btn-default({ .btn-none-y() });
}

.list-transform {
  .list-btn-default({ .btn-transform() });
}

// custom

.lists-special {
  ul.list:not(.lists-nospecial) {
    .list-block();
    .list-space(none);
    .list-primary-empty();
    > li {
      > a, > button {
        .font-sans-medium() !important;
        .btn-none-x() !important;
      }
    }
    &.list-line {
      > li {
        border-bottom: 1px solid @grey-350 !important;
        > a, > button {
          padding-top: 16px !important;
          padding-bottom: 16px !important;
        }
      }
    }
    &.list-transform {
      > li {
        > a, > button {
          .font-sans-semibold() !important;
          font-size: 16px;
          &.level-empty {
            .font-sans-medium() !important;
            font-size: 12px;
            letter-spacing: .1em;
            text-transform: uppercase;
          }
        }
      }
    }
    .level-top {
      .btn-caret();
    }
    .level-sub {
      .margin(bottom, small);
      .btn();
      .btn-primary-empty();
      .btn-none-x();
      .btn-short();
      .font-sans-bold() !important;
      font-size: 12px;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
  }
  .submenu > ul {
    .list();
    .list-block();
    .list-space(tiny);
    .list-primary-empty();
    .padding(bottom, medium);
    > li {
      > strong {
       .btn();
      }
      > a, > button, > strong {
        .font-sans-medium() !important;
        .btn-none-x() !important;
        .btn-short() !important;
      }
    }
  }
}

//////////////////////
// box
//////////////////////

.box {
  // styles
  &::before {
    .design-setup();
    border-radius: @border-radius;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
  }
  // .btn-close
  > .btn-close {
    .btn-tiny();
    .btn-icon();
    position: absolute;
    top: 0;
    right: 0;
  }
  // options
  .box-medium(); // size
  .box-grey(); // variant
}

// variant

.box-grey {
  color: @text;
  &::before {
    border-color: @grey-250;
    background-color: @grey-250;
  }
  > .btn-close {
    .btn-primary-empty();
  }
}

.box-white {
  color: @text;
  &::before {
    border-color: @white;
    background-color: @white;
  }
  > .btn-close {
    .btn-primary-empty();
  }
}

// size

.box-tiny {
  padding: .5em 1em;
  .font-tiny();
}

.box-small {
  padding: .75em 1.25em;
  .font-small();
}

.box-medium {
  padding: 1em 1.5em;
  .font-medium();
}

.box-big {
  padding: 3em 3em;
  .font-medium();
}

.box-giant {
  padding: 3em 4em;
  .font-medium();
}

// special

.box-squared {
  &::before {
    border-radius: 0;
  }
}

.box-noborder {
  border: 0;
}

.box-none {
  padding: 0;
}

.box-tall {
  padding-top: 1.75em;
  padding-bottom: 1.75em;
}

.box-short {
  padding-top: .75em;
  padding-bottom: .75em;
}

.box-wide {
  padding-left: 2em;
  padding-right: 2em;
}

.box-narrow {
  padding-left: 1em;
  padding-right: 1em;
}
