//////////////////////
// shared
//////////////////////

.img-bg-container {
  position: relative;
}

.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// .title-special

.title-special {
  .margin(y, giant);
  .margin(x, auto);
  max-width: 80rem;
  @media @max-sm {
    .font-bigger();
  }
  @media @min-sm {
    .font-giant();
  }
  text-align: center;
}

// .container-max

.container-max {
  .margin(x, auto);
  .padding(x, none);
  max-width: 1920px;
}

// .box-special

.box-special {
  display: block;
  .link-none();
  .margin(y, big);
  .margin(y, huge, sm);
  // order
  @media @max-sm {
    .col-7-md {
      .order(1);
    }
    .col-5-md {
      .order(2);
    }
  }
  // content
  @media @max-sm {
    .box {
      border: 0;
      padding: 3rem 2rem;
    }
  }
  &.box-special-mobile {
    @media @max-sm {
      .box {
        background-color: @grey-200;
        .h2 {
          .font-sans-medium();
          font-size: 1.9rem;
        }
      }
    }
  }
  .box-special-img {
    float: left;
    width: 100%;
    > .inner {
      float: left;
      width: 100%;
      background-color: @grey-200;
    }
    img {
      .margin(x, auto);
    }
  }
  p {
    .muted();
    .max-width-small();
    @media @max-sm {
      .font-sans-regular();
      .font-bigger();
    }
  }
  .box-special-img-y {
    @media @max-sm {
      img {
        .margin-remove(top, medium);
      }
    }
    @media @min-sm {
      img {
        .margin-remove(y, medium);
      }
    }
  }
  .box-special-img-desktop {
    @media @max-sm {
      display: none;
    }
  }
  .box-special-img-mobile {
    @media @min-sm {
      display: none;
    }
  }
}

// .special-item

.special-item {
  display: block;
  .link-none();
  .padding(y, medium);
  width: 20%;
  .font-sans-medium();
  font-size: 11px;
  line-height: 1.6;
  text-align: center;
  img {
    margin: auto;
    max-width: 68px;
    width: 100%;
    height: auto;
  }
  // .header-content
  .header-content & {
    // animations
    img {
      .transition-default.out();
      opacity: 1;
    }
    &.special-out {
      img {
        .transition-default.active();
        opacity: .33;
      }
    }
  }
}
.teams-item {
  img {
    max-width: 110px;
  }
  .banner-teams-container & {
    .padding(x, small);
    img {
      max-width: 85px;
    }
    @media @min-lg {
      .padding(x, small);
      img {
        max-width: 95px;
      }
    }
  }
}

.pilots-img-placeholder,
.pilots-img,
.teams-img-placeholder,
.teams-img,
.products-img-placeholder,
.products-img {
  display: none;
  opacity: 0;
  position: relative;
  padding-bottom: 66.8%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &.special-active {
    display: block;
    .anim-left.active();
    opacity: 1;
  }
}

// .hero

.hero {
  /*
  position: relative;
  z-index: 1;
  */
  .hero-item {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: @grey-250;
    .hero-item-inner {
       position: relative;
      .padding(y, medium);
      .padding(y, big, sm);
      .display(flex);
      .align-items-end();
      height: 37.4rem;
      > * {
        position: relative;
        z-index: 2;
      }
      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        height: 300px;
        background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,.5) 100%);
      }
      @media @max-sm {
        text-align: center;
        .hero-item-content {
          display: none;
        }
      }
    }
    .hero-item-content {
      @media @max-sm {
        display: none;
      }
    }
    .hero-item-content-right {
      .display(flex);
      margin-bottom: -1rem;
      margin-left: -1.8rem;
      margin-right: -1.8rem;
    }
  }
}
.hero-subcat {
  @media @max-sm {
    display: none;
  }
  .hero-item {
    .hero-item-inner {
      .padding(y, 3rem, sm);
      height: 20.2rem;
    }
  }
}

// .special-item-container

.special-item-container {
  .padding(y, big);
}

.products-item-container {
  line-height: 1.4;
  .products-item-cat {
    .link-none();
    .h6();
    .h6.big();
  }
  .products-item-sub-container {
    .margin(top, medium);
  }
  .products-item-sub {
    .link-none();
    .padding(y, .75rem) !important;
    .font-sans-regular();
    font-size: 12px;
    letter-spacing: .05em;
    text-transform: uppercase;
  }
  .products-item-sub, .products-item-cat {
    &:hover {
      text-decoration: underline !important;
    }
  }
  .col-little {
    .col(6);
    .order(1);
    .products-item-sub {
      .col(6);
    }
  }
  .col-big {
    .col(6);
    .order(2);
    .products-item-sub {
      .col(6);
    }
  }
}

// .hero-mobile

body.account {
  @media @max-md {
    .columns {
      .flex-space-y(none);
    }
    .sidebar-main {
      position: sticky;
      z-index: 99;
      top: 0;
      padding-bottom: 2rem;
    }
  }
}

.hero-mobile {
  @media @min-sm {
    display: none;
  }
  position: sticky;
  z-index: 99;
  top: 0;
  .hero-mobile-title {
    .btn-nodesign();
    padding: .8em 1em;
    border-top: 1px solid @grey-350;
    border-bottom: 1px solid @grey-350;
    background-color: @grey-200;
    .font-sans-semibold();
    color: @black;
    font-size: 2rem;
    line-height: 1.4;
    letter-spacing: 0;
    text-transform: none;
  }
  .hero-mobile-title-icon {
    img {
      margin-top: -1rem;
      margin-bottom: -1rem;
      height: 35px;
      width: auto;
    }
  }
  .drop-inner::before {
    border-top: 0 !important;
  }
  .submenu {
    max-height: 32rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.hero-mobile-account {
  @media @min-sm and @max-md {
    display: block;
  }
  .lists-special .submenu > ul {
    .padding(all, none);
  }
  // fix scrolling container
  left: 0;
  &:not(.active) {
    .hero-mobile-inner {
      position: relative;
      margin-left: -1.65rem;
      margin-right: -1.65rem;
      @media @min-sm {
        margin-left: -2.25rem;
        margin-right: -2.25rem;
      }
    }
  }
}

//////////////////////
// index
//////////////////////

// .slider-home

.slider-home {
  position: relative;
  background: @grey-350;
}

.slider-home-item {
  display: block;
  position: relative;
}

.slider-home-item-img-container {
  position: relative;
  width: 100%;
  height: 70rem;
  @media @max-xl {
    height: 60rem;
  }
  @media @max-sm {
    height: 50rem;
  }
  background-color: @grey-250;
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    height: 300px;
    background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,.5) 100%);
  }
}

.slider-home-item-img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-home-item-inner {
  .padding(top, 3rem);
  .padding(bottom, 8rem);
  .padding(y, 4rem, md);
  .display(flex);
  .align-items-end();
  .justify-content-end();
  @media @max-sm {
    text-align: center;
  }
  @media @min-sm {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    margin-left: auto;
    text-align: right;
    max-width: 700px;
  }
}

.slider-home-item {
  .link-none();
  color: @white;
  @media @max-sm {
    color: @black !important;
  }
}

.slider-home-item-intro {
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.2;
  text-transform: uppercase;
  @media @max-sm {
    font-size: 17px;
  }
}

.slider-home-item-title {
  margin-bottom: 30px;
  .font-sans-semibold();
  font-size: 42px;
  line-height: 1.2;
  text-transform: uppercase;
  @media @max-sm {
    margin-bottom: 10px;
    font-size: 30px;
  }
}

.button--topbottom,
.slider-home-item-link {
  .link-none() !important;
  display: inline-block;
  padding: 10px 15px;
  border-top: 1px solid @white;
  border-bottom: 1px solid @white;
  .font-sans-medium();
  font-size: 2.4rem;
  line-height: 1.2;
  text-transform: uppercase;
  @media @max-sm {
    font-size: 1.8rem;
  }
  &.button--topbottom--black {
    border-top: 1px solid @black;
    border-bottom: 1px solid @black;
  }
  @media @max-sm {
    border-color: @black !important;
  }
}

// .slider-home

.slider-black {
  position: relative;
  padding: 2rem;
  @media @min-sm {
    padding-right: 0;
  }
  background: @black;
}

.slider-black-item {
  display: block;
  position: relative;
}

.slider-black-item-img-container {
  position: relative;
  padding-bottom: 56.3%;
}

.slider-black-item-img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.slider-black-item-inner {
  position: relative;
  z-index: 2;
  padding-top: 20px;
  padding-bottom: 5px;
}

.slider-black-item.inverse {
  .link-none();
  color: @white !important;
}

.slider-black-item-gray {
  @media @max-md {
    background: #E8E8E8;
  }
  @media @min-md {
    .flex {
      background: #E8E8E8;
    }
  }
}

.slider-black-item-title {
  margin-bottom: 5px;
  .font-sans-semibold();
  font-size: 3.5rem;
  line-height: 1.2;
  text-transform: uppercase;
  @media @max-md {
    font-size: 2rem;
  }
}

.slider-black-item-link {
  .font-sans-medium();
  font-size: 1.4rem;
  line-height: 1.2;
}

.slider-black-item-special-container {
  padding: 2rem;
}

.slider-black-item-special-content {
  @media @max-md {
    padding-bottom: 4rem;
  }
  @media @min-md {
    padding: 6rem 6rem;
  }
  @media @min-lg {
    padding: 12rem 6rem;
  }
}

.slider-black-item-special-img-container-outside {
  width: 100%;
}

.slider-black-item-special-img-container {
  position: relative;
  padding-bottom: 133%;
  width: 100%;
}

.slider-black-item-special-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.slider-black-item-special-text-1 {
  margin-bottom: 1rem;
  .font-sans-semibold();
  font-size: 5.2rem;
  color: @red;
  line-height: 1;
  text-transform: uppercase;
}

.slider-black-item-special-text-2 {
  margin-bottom: 1rem;
  .font-sans-bold();
  font-size: 3.5rem;
  line-height: 1;
}

.slider-black-item-special-text-3 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
  line-height: 1.4;
  @media @max-md {
    font-size: 1.8rem;
  }
}

.slider-black-item-special-text-4 {
  .link-none() !important;
  font-size: 2.4rem;
  color: @red !important;
  line-height: 1.4;
}

// .banner-special

.slider-special {
  position: relative;
}

.banner-special-container {
  margin-top: 60px;
  margin-bottom: 60px;
  @media @min-md {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.banner-special-item-inner {
  padding-bottom: 149%;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: contain;
  }
}

// .home-foot

.home-foot-container {
  @media @max-sm {
    .margin(top, big);
    .title-special {
      display: none;
    }
  }
  @media @min-sm {
    .margin(top, huge);
    .home-foots {
      .margin(top, giant);
    }
  }
}
.home-foot {
  display: block;
  position: relative;
  .margin(x, auto);
  max-width: 600px;
  .home-foot-img {
    position: relative;
    z-index: 1;
    background-color: @black;
  }
  .home-foot-content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .display(flex);
    .flex-column();
    .align-items-center();
    .justify-content-center();
    .padding(all, medium);
    .padding(all, big, sm);
    text-align: center;
  }
  .h1 {
    margin-bottom: 0;
  }
  .h6 {
    margin-top: 1rem;
  }
  // animations
  .h1 {
    .transition-default.out();
  }
  .home-foot-img {
    overflow: hidden;
    > * {
      .transition-default.out();
      transform: scale(1);
      opacity: 1;
    }
  }
  &:hover {
    .h1 {
      .transition-default.active();
    }
    .home-foot-img {
      > * {
        .transition-default.active();
        transform: scale(1.05);
        opacity: .75;
      }
    }
  }
  @media @min-sm {
    .h1 {
      .transition-default.out();
      transition-duration: @time-giant;
      transform: translateY(1.5rem);
    }
    .h6 {
      .transition-default.out();
      transform: translateY(-.5rem);
      opacity: 0;
    }
    &:hover {
      .h1 {
        .transition-default.active();
        transform: translateX(0);
      }
      .h6 {
        .transition-default.active();
        transition-duration: @time-giant;
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

// .anim-borders

@anim-borders-color: @white;
@anim-borders-size-y: 20px;
@anim-borders-size-x: 20px;

.anim-borders {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.anim-borders-top {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: @anim-borders-size-y;
    background-color: @anim-borders-color;
  }
}
.anim-borders-bottom {
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: @anim-borders-size-y;
    background-color: @anim-borders-color;
  }
}
.anim-borders-left {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: @anim-borders-size-x;
    height: 100%;
    background-color: @anim-borders-color;
  }
}
.anim-borders-right {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: @anim-borders-size-x;
    height: 100%;
    background-color: @anim-borders-color;
  }
}
.anim-borders-activation {
  overflow: hidden;
  .anim-borders::before,
  .anim-borders::after {
    .transition-default.out();
  }
  .anim-borders-top::before {
    transform: translateY(-@anim-borders-size-y);
  }
  .anim-borders-bottom::before {
    transform: translateY(@anim-borders-size-y);
  }
  .anim-borders-left::after {
    transform: translateX(-@anim-borders-size-x);
  }
  .anim-borders-right::after {
    transform: translateX(@anim-borders-size-x);
  }
  &:hover, &:active, &.active {
    .anim-borders::before,
    .anim-borders::after {
      .transition-default.active();
    }
    .anim-borders-top::before {
      transform: translateY(0);
    }
    .anim-borders-bottom::before {
      transform: translateY(0);
    }
    .anim-borders-left::after {
      transform: translateX(0);
    }
    .anim-borders-right::after {
      transform: translateX(0);
    }
  }
}

//////////////////////
// category
//////////////////////

// .category-subcat

.category-subcat {
  .display(flex);
  .margin(top, mediumbig);
}

//////////////////////
// checkout
//////////////////////

// success and faliure

.customer-account-logoutsuccess,
.checkout-cart-index,
.checkout-onepage-success,
.checkout-onepage-failure {
  .page-title-wrapper {
    .margin(x, auto);
    max-width: 38rem;
    .h4();
    text-align: center;
    .page-title {
      .h4();
    }
    a {
      .block();
      .btn();
      .btn-link();
      .btn-small();
      .btn-short();
    }
  }
  .main-container {
    .display(flex);
    .align-items-center();
  }
}

.account-logoutsuccess,
.cart-empty,
.checkout-success,
.checkout-failure {
  .margin(x, auto);
  max-width: 38rem;
  .font-small();
  line-height: 1.6;
  text-align: center;
  p {
    .margin(all, none);
  }
  .actions-toolbar div.primary {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

//////////////////////
// contact
//////////////////////

.contact-index-index {
  h4 {
    .margin(bottom, mediumbig);
  }
}

@media @max-xs{
  .hidden-xs{
    display:none !important;
  }
}

@media @max-sm{
  .hidden-sm{
    display:none !important;
  }
}

//////////////////////
// search
//////////////////////

.catalogsearch-result-index {
  .page-title-wrapper {
    .margin(top, big);
    text-align: center;
  }
  .page-title {
    .font-sans-medium();
    font-size: 2.3rem;
  }
}

#search_autocomplete {
  display: none !important;
}

.search {
}

.search-noitems {
  text-align: center;
}
