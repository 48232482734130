//////////////////////
// reset
//////////////////////

html,
body {
  height: 100%;
  touch-action: manipulation;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a img {
  // ie10 remove border
  border: 0;
  outline: 0;
}

a, button, .alink {
  // reset
  cursor: pointer;
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
  text-align: left;
}

img {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

address {
  font-style: inherit;
}

dl, dd {
  margin: 0;
}

fieldset, legend {
  border: 0;
  margin: 0;
  padding: 0;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea {
  // ios remove shadow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  cursor: pointer;
  // remove arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}

textarea {
  resize: vertical;
}


html:not(.xt-focus) {
  // focus disable
  :focus {
    outline: 0;
  }
}

html.xt-focus {
  // focus chrome style
  input[type="checkbox"], input[type="radio"] {
    &:focus ~ .label-control::before {
      outline-color: rgb(77, 144, 254);
      outline-offset: -2px;
      outline-style: auto;
      outline-width: 5px;
    }
  }
}
