//////////////////////
// columns
//////////////////////

.footer-content {
  @media @max-sm {
    overflow: hidden;
  }
}

.columns {
  .flex();
  .flex-space(none);
  .flex-space-y(big);
  .flex-space(big, sm);
  .flex-row();
  .flex-wrap();
}

// 1column

.page-layout-1column,
.page-layout-checkout,
.page-layout-full {
  .column.main {
    .col(12);
  }
  .sidebar {
    .col(12);
  }
}

// 2columns sizes

.page-layout-2columns-left,
.page-layout-2columns-right {
  .column.main {
    .col(12);
    .col(8, md);
    .col(9, lg);
  }
  .sidebar {
    .col(12);
    .col(4, md);
    .col(3, lg);
  }
}

// 2columns order with float

.page-layout-2columns-left {
  @media @min-md {
    .columns {
      .display(block);
    }
    .column.main {
      .float(right);
      clear: right;
    }
    .sidebar {
      .float(left);
      clear: left;
    }
  }
}
.page-layout-2columns-right {
  @media @min-md {
    .columns {
      .display(block);
    }
    .column.main {
      .float(left);
      clear: left;
    }
    .sidebar {
      .float(right);
      clear: right;
    }
  }
}

// 2columns order mobile

.page-layout-2columns-left,
.page-layout-2columns-right {
  @media @max-md {
    .column.main {
      .order(1);
    }
    .sidebar {
      .order(0);
    }
    .sidebar-additional {
      .order(2);
    }
  }
}

//////////////////////
// product
//////////////////////

.catalog-product-view {
  // reset
  .columns {
    .display(block);
    .flex-space(none);
  }
  .column.main {
    width: auto !important;
    max-width: none !important;
  }
  // columns with flex
  .column.main {
    .flex();
    .flex-space(none);
    .flex-space-y(big);
    .flex-space(big, sm);
    .flex-row();
    .flex-wrap();
  }
  .product.media {
    .col(12);
    .col(7, sm);
    .col(8, md);
    .order(0);
  }
  .product-group-content {
    .col(12);
    .col(7, sm);
    .col(8, md);
    .order(2);
  }
  .product-info-main {
    .col(12);
    .col(5, sm);
    .col(4, md);
    .order(1);
    @media @min-sm {
      position: sticky;
      z-index: 99;
      top: 10rem;
      bottom: 10rem;
    }
  }
}

//////////////////////
// cart
//////////////////////

/*
.cart-container {
  .flex();
  .flex-space(big);
  .flex-row();
  .flex-wrap();
  // sizes
  .form-cart {
    .col(12);
    .col(8, md);
  }
  #gift-options-cart {
    .col(12);
    .col(8, md);
  }
  .cart-discount {
    .col(12);
    .col(8, md);
  }
  .cart-summary-outer {
    .col(12);
    .col(4, md);
  }
  // order with float
  @media @min-md {
    .display(block);
    .form-cart {
      .float(left);
    }
    #gift-options-cart {
      .float(left);
    }
    .cart-discount {
      .float(left);
    }
    .cart-summary-outer {
      .float(right);
      clear: right;
    }
  }
  // order mobile
  @media @max-md {
    .form-cart {
      .order(1);
    }
    #gift-options-cart {
      .order(2);
    }
    .cart-discount {
      .order(3);
    }
    .cart-summary-outer {
      .order(0);
    }
  }
}
*/

//////////////////////
// checkout
//////////////////////

.checkout-index-index {
  // reset
  .columns {
    .display(block);
    .flex-space(none);
  }
  .column.main {
    width: auto !important;
    max-width: none !important;
  }
  // sizes
  .checkout-container {
    .flex();
    .flex-space(none);
    .flex-space-y(big);
    .flex-space(big, sm);
    .flex-column();
    .flex-wrap();
  }
  .opc-progress-bar {
    .col(12);
    .opc-progress-bar-inner {
      .col(12);
      .col(8, sm);
      .col(7, md);
      .col(6, lg);
      .margin(x, auto);
    }
  }
  .opc-wrapper {
    .col(12);
    .col(8, sm);
    .col(7, md);
    .col(6, lg);
    .margin(x, auto);
  }
  .opc-estimated-wrapper {
    .col(12);
    .col(8, sm);
    .col(7, md);
    .col(6, lg);
    .margin(x, auto);
  }
  #opc-sidebar {
    .col(12);
    .col(8, sm);
    .col(7, md);
    .col(6, lg);
    .margin(x, auto);
  }
  // order with flex
  .opc-container {
    .display(flex);
    .flex-column();
  }
  .opc-wrapper {
    .order(3);
  }
  .opc-sidebar {
    .order(2);
  }
}

//////////////////////
// contain
//////////////////////

body:not(.page-layout-full):not(.page-layout-checkout) {
  .main-container {
    &:extend(.container);
  }
}

body.page-layout-1column, body.account {
  .main-container {
    max-width: 1280px !important;
  }
}

//////////////////////
// sticky footer
//////////////////////

.page-wrapper {
  .display(flex);
  .flex-column();
  min-height: 100vh;
}
.main-container {
  .flex-auto();
}
