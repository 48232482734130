//////////////////////
// default
//////////////////////

// .animation-default();

/* USAGE:
.anim-opacity.active();
opacity: 1;
&:hover, &:active, &.active {
  .anim-opacity.out();
  opacity: 0;
}
*/

/* USAGE:
&.active {
  .inner {
    .anim-opacity.active();
    opacity: 1;
  }
}
&.out {
  .inner {
    .anim-opacity.out();
    opacity: 0;
  }
}
*/

.animation-default() {
  &.active {
    animation-duration: @time-medium;
    animation-timing-function: @bez-in;
  }
  &.out {
    animation-duration: @time-medium;
    animation-timing-function: @bez-out;
  }
}

// .transition-default();

/* USAGE:
opacity: 0;
.transition-default.out();
&:hover, &:active, &.active {
   opacity: 1;
  .transition-default.active();
}
*/

/* USAGE:
&.active {
  .inner {
    opacity: 1;
    .transition-default.active();
  }
}
&.out {
  .inner {
    opacity: 0;
    .transition-default.out();
  }
}
*/

.transition-default() {
  &.active {
    transition-property: all;
    transition-duration: @time-medium;
    transition-timing-function: @bez-in;
  }
  &.out {
    transition-property: all;
    transition-duration: @time-medium;
    transition-timing-function: @bez-out;
  }
}

// anim-opacity

.anim-opacity {
  &.active {
    .animation-default.active();
    animation-name: anim-opacity-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-opacity-out;
  }
}

@keyframes anim-opacity-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anim-opacity-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// anim-zoom

.anim-zoom {
  &.active {
    .animation-default.active();
    animation-name: anim-zoom-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-zoom-out;
  }
}

@keyframes anim-zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1, 1.4);
  }
}

@keyframes anim-zoom-out {
  0% {
    transform: scale(1, 1.4);
  }
  100% {
    transform: scale(1);
  }
}

// anim-vertical

.anim-vertical {
  &.active {
    .animation-default.active();
    animation-name: anim-vertical-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-vertical-out;
  }
}

@keyframes anim-vertical-in {
  0% {
    opacity: 0;
    transform: translateY(-@anim-medium);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-vertical-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(@anim-medium);
  }
}

// anim-horizontal

.anim-horizontal {
  &.active {
    .animation-default.active();
    animation-name: anim-horizontal-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-horizontal-out;
  }
}

@keyframes anim-horizontal-in {
  0% {
    opacity: 0;
    transform: translateX(-@anim-medium);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes anim-horizontal-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(@anim-medium);
  }
}

// anim-top

.anim-top {
  &.active {
    .animation-default.active();
    animation-name: anim-top-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-top-out;
  }
}

@keyframes anim-top-in {
  0% {
    opacity: 0;
    transform: translateY(-@anim-medium);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes anim-top-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-@anim-medium);
  }
}

// anim-bottom

.anim-bottom {
  &.active {
    .animation-default.active();
    animation-name: anim-bottom-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-bottom-out;
  }
}

@keyframes anim-bottom-in {
  0% {
    opacity: 0;
    transform: translateY(@anim-medium);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes anim-bottom-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateY(@anim-medium);
  }
}

// anim-left

.anim-left {
  &.active {
    .animation-default.active();
    animation-name: anim-left-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-left-out;
  }
}

@keyframes anim-left-in {
  0% {
    opacity: 0;
    transform: translateX(-@anim-medium);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-left-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-@anim-medium);
  }
}

// anim-right

.anim-right {
  &.active {
    .animation-default.active();
    animation-name: anim-right-in;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-right-out;
  }
}

@keyframes anim-right-in {
  0% {
    opacity: 0;
    transform: translateX(@anim-medium);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-right-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(@anim-medium);
  }
}

//////////////////////
// custom
//////////////////////

// collapse

.collapse-height,
.collapse-width {
  &.active {
    .transition-default.active();
  }
  &.out {
    .transition-default.out();
  }
}

// xt-fade

.fade {
  &:not(.fade-scroll) {
    opacity: 0;
    &.in {
      .anim-horizontal.active();
      opacity: 1;
    }
    &.out {
      .anim-horizontal.out();
      opacity: 0;
    }
    // custom
    &.title-special,
    &.special-item-inner,
    &.toolbar,
    &.page-footer,
    &.footer-banner-item,
    .hero-item-content &,
    .category-subcat-head & {
      &.in {
        .anim-bottom.active();
      }
    }
    &.page-footer {
      .accelerate(); // @FIX checkout footer
    }
  }
}

// .anim-flow-horizontal

.anim-flow-horizontal {
  &.active {
    //visibility: visible;
    .transition-default.active();
    left: 0;
    width: 100%;
    opacity: 1;
    transform: scaleX(1);
  }
  &.out {
    //visibility: hidden;
    .transition-default.out();
    left: 0;
    width: 100%;
    opacity: 0;
    transform: scaleX(0);
  }
}

/* @BUG safari
.anim-flow-horizontal {
  &.active {
    //visibility: visible;
    .animation-default.active();
    animation-name: anim-flow-horizontal-in;
    .transition-default.active();
    left: 0;
    width: 100%;
    opacity: 1;
    transform: translateX(0);
  }
  &.out {
    //visibility: hidden;
    .transition-default.out();
    left: 100%;
    width: 0;
    opacity: 0;
    transform: translateX(@anim-medium);
  }
}

@keyframes anim-flow-horizontal-in {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
    transform: translateX(-@anim-medium);
  }
  100% {
    left: 0;
    width: 100%;
    opacity: 1;
    transform: translateX(0);
  }
}
*/