//////////////////////
// checkout
//////////////////////

// page

.checkout-index-index {
  .page-title-wrapper {
    .sr-only();
  }
}

// .opc-progress-bar

.opc-progress-bar {
  .opc-progress-bar-line {
    float: left;
    width: 100%;
    .margin(top, medium);
    @media @min-sm {
      .margin(bottom, medium);
    }
    border-bottom: 2px solid @grey-300;
  }
  .opc-progress-bar-inner {
    .display(flex);
    .align-items-stretch();
  }
  .opc-progress-bar-item {
    .display(flex);
    .align-items-center();
    .justify-content-center();
    position: relative;
    padding: 2.5rem 0;
    line-height: 1.2;
    text-align: center;
    @media @max-sm {
      text-align: left;
      padding: 2rem 0;
    }
    // content
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: @black;
      .anim-flow-horizontal.out();
    }
    .opc-progress-bar-icon {
      display: inline-block;
      padding: .1rem;
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 50%;
      background-color: @grey-300;
      .font-sans-bold();
      font-size: 13px;
      line-height: 1.7;
      text-align: center;
      .transition-default.active();
    }
    .opc-progress-bar-title {
      display: inline-block;
      .font-sans-bold();
      font-size: 13px;
      color: @black;
      text-transform: uppercase;
      letter-spacing: .05em;
      @media @max-sm {
        max-width: 70%;
      }
      .transition-default.active();
    }
    // activation
    &._complete {
      cursor: pointer;
      &:hover, &:active {
        .opc-progress-bar-icon {
          background-color: @black;
          color: @white;
        }
        .opc-progress-bar-title {
          color: @black;
        }
      }
    }
    &._active {
      &::after {
        .anim-flow-horizontal.active();
      }
      .opc-progress-bar-icon {
        background-color: @black;
        color: @white;
      }
      .opc-progress-bar-title {
        color: @black;
      }
    }
  }
  // count
  &.count-1 {
    .opc-progress-bar-item {
      width: 100%;
    }
  }
  &.count-2 {
    .opc-progress-bar-item {
      width: 50%;
    }
  }
}

// .opc-wrapper

.opc-wrapper {
  .step-title {
    .h5-block();
  }
  .opc {
    .flex();
    .flex-space(none);
    .flex-space-y(big);
    .flex-space(big, sm);
    > li {
      .col(12);
      opacity: 1 !important;
    }
  }
  .action {
    .btn-primary-border();
  }
  .legend {
    .display(none);
  }
  .field.street {
    label.label {
      display: none;
    }
    .field:first-child {
      .padding(all, none) !important;
    }
  }
  input[type="radio"] {
    display: block !important;
  }
  .actions-toolbar {
    .action-update,
    .action.primary {
      .btn-primary();
    }
  }
  .form-login {
    .actions-toolbar {
      .margin(top, none);
      .margin(bottom, big);
    }
  }
}

// .table-checkout-shipping-method

.table-checkout-shipping-method {
  thead {
    .display(none);
  }
  span {
    display: inline-block;
    position: relative;
    & + span {
      .margin(left, small) !important
    }
    &.col-method {
      .font-sans-regular();
    }
    &.col-price {
      .font-sans-regular();
    }
  }
}

// .shipping-address-items

.shipping-address-items {
  .flex();
  .shipping-address-item {
    .col(12);
    .col(6, md);
    .shipping-address-label {
      display: none;
      .h6();
      .h6.small();
    }
    .shipping-address-item-inner {
      cursor: pointer;
      .padding(all, medium);
      border: 1px solid @grey-350;
      .font-small();
    }
    &.selected-item {
      .shipping-address-label {
        display: block;
      }
      .shipping-address-item-inner {
        cursor: default;
      }
    }
    &.selected-item {
      .shipping-address-item-inner {
        border: 1px solid @black;
      }
    }
  }
  .action {
    .margin(top, small);
    .margin(right, tiny);
    .btn-tiny() !important;
  }
}

#opc-new-shipping-address {
  .modal-popup & {
    display: block !important;
  }
}

// .checkout-payment-method

.checkout-payment-method {
  .payment-method-note {
    .padding(all, medium);
    border: 1px solid @grey-350;
    .font-small();
  }
  .payment-method {
    .payment-method-content {
      .margin(bottom, mediumbig);
      display: none;
    }
    &._active .payment-method-content {
      display: block;
    }
  }
  .checkout-agreements {
    .margin(y, medium);
  }
  .payment-method-content > .actions-toolbar {
    .margin(y, medium);
  }
  .payment-icon,
  .action-help {
    display: none;
  }
}

// .checkout-billing-address

.checkout-billing-address {
  .padding(all, medium);
  border: 1px solid @grey-350;
  .billing-address-details-inner {
    .font-small();
  }
  .field-select-billing {
    .form-group-small();
    .padding(all, none);
    .label {
      display: none;
    }
  }
  .action {
    .margin(top, small);
    .margin(right, tiny);
    .btn-tiny() !important;
  }
  .billing-address-form {
    .font-small();
    .form-small();
  }
  .actions-toolbar {
    .margin(top, none);
  }
}

// .discount-code

.discount-code {
  .margin(bottom, big);
  .field {
    .form-group-tiny();
    .padding(all, none);
  }
  .action {
    .btn-tiny() !important;
    html:not(.ie11) & {
      white-space: nowrap;
    }
  }
}

// #opc-sidebar

#opc-sidebar {
  .margin(bottom, 6rem);
  > .title {
    .block();
    .h5-block();
  }
}

// .shipping-information

.opc-block-shipping-information {
  .margin(bottom, big);
  .shipping-information {
    .flex();
    > div {
      .col(12);
      .col(6, md);
    }
    .shipping-information-title {
      position: relative;
      cursor: pointer;
      .h6-line();
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: @black;
        .anim-flow-horizontal.out();
      }
      &:hover, &:active {
        &::after {
          .anim-flow-horizontal.active();
        }
      }
      .action {
        display: none;
      }
    }
    .shipping-information-content {
      .font-small();
    }
  }
}

// .opc-block-summary

.opc-block-summary {
  .opc-block-summary-inner {
    .flex();
  }
  .table-totals {
    .order(1);
  }
  .total-message {
    .order(2);
  }
  .items-in-cart {
    .order(0);
    .title {
      .h6();
      .h6.small();
    }
    .minicart-items.content {
      .display(block) !important;
      border-bottom: 1px solid @grey-350;
      .product-item {
        .padding(y, mediumbig);
        border-top: 1px solid @grey-350;
      }
      .product {
        .flex();
        .flex-nowrap();
        line-height: 1.4;
      }
      .product-image-container {
        width: 100px !important;
        @media @max-sm {
          display: none;
        }
      }
      .details-qty {
        margin-left: auto;
        font-size: 1.4rem;
        white-space: nowrap;
      }
      .subtotal {
        .price-container();
        width: auto;
        .font-sans-semibold();
        font-size: 1.4rem;
        @media @max-sm {
          min-width: 10rem;
          text-align: right;
        }
      }
    }
  }
}

// .opc-estimated-wrapper

.opc-estimated-wrapper {
  display: none;
}

// .table-totals

.table-totals {
  width: 100%;
  tr {
    .flex();
    .flex-space(none);
    .align-items-center();
    .justify-content-between();
    border: 0;
  }
  th {
    display: block;
    .font-sans-medium();
    font-size: 1.4rem;
    color: @black;
    text-transform: none;
    strong {
      .font-sans-bold();
      font-size: 1.8rem;
      color: @black;
      text-transform: none;
    }
  }
  td {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    .price-container();
    width: auto;
    .font-sans-semibold();
    font-size: 1.4rem;
    color: @black;
    strong {
      .font-sans-bold();
      font-size: 2.1rem;
      color: @black;
    }
  }
  tr.grand.totals {
    .margin(top, medium);
    .padding(top, medium);
    border-top: 1px solid @grey-350;
  }
}
