//////////////////////
// category
//////////////////////

// .product-items

.product-items {
  .margin(y, medium);
  // .product-item
  .product-item {
    .link-none();
    .margin(bottom, medium);
    text-align: center;
    // column
    .product-item-photo {
      .block();
      img {
        .responsive();
      }
    }
    // content
    .product-item-details {
      .flex-auto();
    }
  }
}

// .slider-products

.slider-products {
  position: relative;
}

// .products-slider

.products-slider {
  margin-top: 60px;
  margin-bottom: 60px;
  @media @min-md {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
