//////////////////////
// group
//////////////////////

.form-group {
  position: relative;
}

// selectors

.form-groups(@rules) {
  .form-group {
    @rules();
  }
}

// components

.form-group-flex {
  .display(flex);
}

.form-group-horizontal {
  .display(flex);
  .label-control {
    .margin(all, none);
  }
}

.form-group-inline {
  .display(flex);
  .flex-wrap();
  .input-control {
    width: auto;
  }
}

//////////////////////
// label
//////////////////////

.label-control {
  .display(block);
  position: relative;
  .user-select-none();
}

//////////////////////
// input
//////////////////////

.input-control {
  .display(block);
  width: 100%;
  z-index: 1;
  &:hover, &:active, &.active {
    z-index: 2;
  }
  // select arrow
  select& {
    .padding(right, @select-space-size * 2) !important;
    white-space: normal;
    background-position:
      calc(~'100% - ' @select-space-size / 2) 50%,
      calc(~'100% - (' @select-space-size / 2 ~') + ' @select-arrow-size) 50%,
      100% 0;
    background-size:
      @select-arrow-size @select-arrow-size,
      @select-arrow-size @select-arrow-size,
      @select-space-size 100%;
    background-repeat: no-repeat;
  }
}

//////////////////////
// check and radio
//////////////////////

// label

input[type="checkbox"], input[type="radio"] {
  & ~ .label-control {
    .label-checkradio();
  }
}

// unstyled

input[type="checkbox"].unstyled, input[type="radio"].unstyled {
  display: block;
  float: left;
  & ~ .label-control {
    display: block;
    overflow: hidden;
  }
}

// check and radio styled
body > *:not(#iubenda-cs-banner):not(#iubenda-iframe) {
  input[type="checkbox"]:not(.unstyled),
  input[type="radio"]:not(.unstyled) {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & ~ .label-control {
      &::before,
      &::after {
        content: '';
        position: absolute !important;
        top: 0;
        left: 0;
      }

      &::before {
        pointer-events: none;
      }

      &::after {
        .display(flex) !important;
        .align-items-center();
        .justify-content-center();
      }
    }
  }
}

//////////////////////
// input-group
//////////////////////

.input-group {
  position: relative;
  .display(flex);
  .flex-nowrap();
  width: 100%;
  // .input-control
  .input-control {
    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0 !important;
    }
  }
}

// shared

.input-group-line,
.input-group-stack {
  .btn {
    .text-align(center);
  }
  .input-control ~ & {
    .btn {
      .margin(right, none);
    }
  }
}

// .input-group-line

.input-group-line {
  .display(flex);
  .flex-nowrap();
  // border
  &:first-child {
    .btn {
      &::before {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
  &:last-child {
    .btn {
      &::before {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
  &:not(:first-child):not(:last-child) {
    .btn {
      &::before {
        border-radius: 0 !important;
      }
    }
  }
}

// .input-group-stack

.input-group-stack {
  .display(flex);
  .flex-row();
  .flex-wrap();
  // .btn
  .btn {
    width: 100%;
  }
  .btn + .btn {
    margin-top: -@btn-border-width;
  }
  &:first-child {
    .btn {
      &:not(:first-child) {
        &::before {
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
        }
      }
      &:not(:last-child) {
        &::before {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
      &:not(:first-child):not(:last-child) {
        &::before {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }
  &:last-child {
    .btn {
      &:not(:first-child) {
        &::before {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
      &:not(:last-child) {
        &::before {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
      &:not(:first-child):not(:last-child) {
        &::before {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
    }
  }
}
