//////////////////////
// fieldset and legend
//////////////////////

fieldset + fieldset {
  .margin(top, medium);
}

legend {
  .block();
  .h4-line();
}

//////////////////////
// group
//////////////////////

.form-group {
  // options
  .form-group-medium();
}

// components

.form-group-flex {
  .flex-space-x(medium);
}

.form-group-flex-sm {
  @media @min-sm {
    .form-group-flex();
  }
}

.form-group-horizontal {
  .flex-space-x(medium);
  .align-items-center();
  .text-align(right);
}

.form-group-horizontal-sm {
  @media @min-sm {
    .form-group-horizontal();
  }
}

.form-group-inline {
  .list-space-x(medium);
  .align-items-baseline();
}

.form-group-inline-sm {
  @media @min-sm {
    .form-group-inline();
  }
}

// size

.form-group-tiny {
  .padding(bottom, 1em);
  font-size: 10px;
}

.form-tiny {
  .form-groups({ .form-group-tiny() });
}

.form-group-small {
  .padding(bottom, 1.25em);
  font-size: 13px;
}

.form-small {
  .form-groups({ .form-group-small() });
}

.form-group-medium {
  .padding(bottom, 1.5em);
  font-size: 14px;
}

.form-medium {
  .form-groups({ .form-group-medium() });
}

.form-group-big {
  .padding(bottom, 1.5em);
  font-size: 1.5rem;
}

.form-big {
  .form-groups({ .form-group-big() });
}

.form-group-giant {
  .padding(bottom, 1.5em);
  font-size: 1.6rem;
}

.form-giant {
  .form-groups({ .form-group-giant() });
}

// special

.form-group-none {
  .padding(all, none);
}

.form-none {
  .form-groups({ .form-group-none() });
}

//////////////////////
// label
//////////////////////

.label-control {
  // styles
  .margin(bottom, 1em); // from .form-group font size
  .font-sans-medium();
  font-size: .9em; // from .form-group font size
  color: @text;
  line-height: 1.25;
  letter-spacing: .05em;
  text-transform: uppercase;
}

//////////////////////
// input
//////////////////////

.input-control {
  // styles
  padding: 1em 1.2em;
  border-radius: @border-radius;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  .font-sans-regular();
  font-size: 16px; // 16 px fixed value
  line-height: 1.25;
  // options
  .input-control-secondary();
  // animations
  .transition-default.out();
  &:hover, &.active {
    .transition-default.active();
  }
  &:focus {
    .transition-default.active();
  }
}

// variant

.input-control-secondary {
  border-color: @black;
  background-color: @white;
  color: @black;
  &:hover, &.active, &:focus {
    border-color: fade(@black, 75%);
    background-color: @white;
    color: @text;
  }
  &:focus {
    border-color: fade(@black, 75%)
  }
  // select arrow
  select& {
    @select-arrow-color: @black;
    @select-space-color: @grey-400;
    background-image: linear-gradient(45deg, transparent 50%, @select-arrow-color 50%),
    linear-gradient(135deg, @select-arrow-color 50%, transparent 50%),
    linear-gradient(to right, @select-space-color, @select-space-color);
  }
}

.input-control-white {
  border-color: @white;
  background-color: @white;
  color: fade(@text, 100% - @perc-lg);
  &:hover, &.active, &:focus {
    border-color: @white;
    background-color: @white;
    color: @text;
  }
  &:focus {
    border-color: @white;
  }
}

// placeholder

:-ms-input-placeholder {
  color: @black;
}
:-moz-placeholder {
  color: @black;
}
::-webkit-input-placeholder {
  color: @black;
}
::-ms-input-placeholder {
  color: @black;
}
::-moz-placeholder {
  color: @black;
}
::placeholder {
  color: @black;
}

//////////////////////
// check and radio
//////////////////////

// label

.label-checkradio {
  .margin(all, none); // reset .label-control
  .padding(left, small); // space from checkbox/radio
  // styles
  .font-sans-medium();
  font-size: 13px;
  line-height: 1.25;
  letter-spacing: 0;
  text-transform: none;
}

// check and radio styled
body > *:not(#iubenda-cs-banner):not(#iubenda-iframe) {
  input[type="checkbox"]:not(.unstyled),
  input[type="radio"]:not(.unstyled) {
    & ~ .label-control {
      padding-left: 3rem;

      &::before,
      &::after {
        width: 1.8rem;
        height: 1.8rem;
      }

      &::before {
        background-color: @white;
        border: 1px solid @grey-400;
      }

      &:after {
        color: @primary;
      }
    }

    // disabled
    &:disabled ~ .label-control {
      &::before {
        .disabled();
      }

      &::after {
        .disabled();
      }
    }

    // animation
    & ~ .label-control {
      &::before {
        .transition-default.out();
        transform: scale(.9, .9);
      }

      &::after {
        .transition-default.out();
        opacity: 0 !important;
        transform: scale(.9, .5);
      }
    }

    &:checked ~ .label-control {
      &::before {
        .transition-default.active();
        transform: scale(1, 1);
      }

      &::after {
        .transition-default.active();
        opacity: 1 !important;
        transform: scale(1, 1);
      }
    }
  }
  // checkbox styled

  input[type="checkbox"]:not(.unstyled) {
    & ~ .label-control {
      &::before {
        border-radius: 4px;
      }
      &::after {
        .xt-icon();
        content: @icon-check;
        font-size: 100%;
      }
    }
  }
  // radio styled

  input[type="radio"]:not(.unstyled) {
    & ~ .label-control {
      &::before {
        border-radius: 50%;
      }
      &::after {
        .xt-icon();
        content: @icon-circle;
        font-size: 55%;
      }
    }
  }
}
//////////////////////
// input-group
//////////////////////

.input-group {
  .input-control {
    z-index: 1;
    &:focus {
      z-index: 3;
    }
  }
}
.input-group-line,
.input-group-stack {
  z-index: 2;
}

//////////////////////
// note
//////////////////////

.note {
  .margin(top, small);
  // styles
  .display(block);
  width: 100%;
  .muted();
  .font-small();;
  line-height: 1.4;
  .text-align(left);
}
