
//////////////////////
// xtend
//////////////////////

@import '_xtend2mage';
@import '../xtend-library/core/__core'; // put package manager xtend-library core path
@import '../xtend-library/theme/__theme'; // put xtend-library theme path

// set to false to not generate any classes
// set to true to only generate non responsive classes
// set to array of breakpoints to generate non responsive classes and responsive classes for those breakpoints

@classes-icons: true;
@classes-util: sm, md, lg;
@classes-margin: false;
@classes-padding: false;
@classes-layout: sm, md, lg;

//////////////////////
// icon
//////////////////////

// font-face

@icons-family: 'icomoon';
@icons-path: '../icons/fonts/icomoon';

@font-face {
  font-family: @icons-family;
  src: url('@{icons-path}.eot');
  src: url('@{icons-path}.eot?#iefix') format('embedded-opentype'),
  url('@{icons-path}.woff') format('woff'),
  url('@{icons-path}.ttf') format('truetype'),
  url('@{icons-path}.svg#@{icons-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

// icons
// USAGE MARKUP: <span class="icon-close"></span>
// USAGE LESS : .icon(@content: @icon-close, @size: @icon-small, @sr-only: false, @position: after);

@icons: close, chevron-up, chevron-left, chevron-right, chevron-down, arrow-left, arrow-right,
payment-amex, payment-mastercard, payment-paypal, payment-postepay, payment-visa,
cart, delivery, return, shipping,
social-fb, social-ig, social-tt, social-x, social-yt, social-pt, arrow-right-new, chevron-down1, chevron-up1, search, lang; // this will generate 

// xtend icons

@icon-chevron-down: '\e903';
@icon-chevron-left: '\e904';
@icon-chevron-right: '\e905';
@icon-chevron-up: '\e906';
@icon-close: '\e907';
@icon-check: '\e915';
@icon-circle: '\e916';

// custom

@icon-arrow-left: '\e900';
@icon-arrow-right: '\e901';
@icon-delivery: '\e908';
@icon-payment-amex: '\e909';
@icon-payment-mastercard: '\e90a';
@icon-payment-paypal: '\e90b';
@icon-payment-postepay: '\e90c';
@icon-payment-visa: '\e90d';
@icon-return: '\e90e';
@icon-shipping: '\e90f';
@icon-social-fb: '\e910';
@icon-social-ig: '\e911';
@icon-social-tt: '\e91f';
@icon-social-x: '\e91a';
@icon-social-yt: '\e91c';
@icon-social-pt: '\e912';
@icon-arrow-right-new: '\e917';
@icon-chevron-down1: '\e918';
@icon-chevron-up1: '\e919';
@icon-search: '\e91b';
@icon-lang: '\e91d';

// luma icons

@icon-menu: '\e914';
@icon-search: '';
@icon-cart: '\e902';
@icon-edit: '';
@icon-arrow-up: '';
@icon-arrow-down: '';
@icon-wishlist: '';
@icon-wishlist-empty: '';
@icon-trash: '';
@icon-prev: '';
@icon-next: '';
@icon-grid: '';
@icon-list: '';
@icon-star: '';
@icon-star-empty: '';
@icon-compare: '';
@icon-compare-empty: '';
@icon-envelope: '';
@icon-checkmark: '';

//////////////////////
// theme
//////////////////////

// modules

@import 'modules/_catalog';
//@import 'modules/_catalog-compare';
@import 'modules/_catalog-gallery';
@import 'modules/_catalog-product';
//@import 'modules/_catalog-search';
@import 'modules/_checkout';
//@import 'modules/_checkout-cart';
@import 'modules/_checkout-minicart';
@import 'modules/_customer';
//@import 'modules/_gift';
@import 'modules/_layered-navigation';
@import 'modules/_newsletter';
//@import 'modules/_review';
@import 'modules/_sales';
//@import 'modules/_swatches';
@import 'modules/_various';
//@import 'modules/_wishlist';

// custom

@import '_layout';
@import '_slider';
@import '_shared';
@import '_header';
@import '_footer';
@import '_page';

//////////////////////
// magento
//////////////////////

@sidebar__background-color:'#FFF';
