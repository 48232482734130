//////////////////////
// block
//////////////////////

.block-reorder {
  .display(none) !important;
}

//////////////////////
// account
//////////////////////

.order-status {
  .alert();
}

.order-date {
  .margin(top, small);
  .font-small();
  .label {
    .label-control();
    .display(inline-block);
    .margin(right, tiny);
  }
}

.order-links {
  .display(none);
}

.table-wrapper.order-items {
  .margin(bottom, big);
  .order-title {
    .h4-line();
    strong {
      .h4();
      .h4.small();
    }
  }
  .label {
    .margin(right, tiny);
    .reset-text();
    &::after {
      content: ':';
    }
  }
  & + .actions-toolbar {
    display: none;
  }
  /*
  .col.options {
    .order-gift-message {
      .margin(top, small);
      .box();
      .box-tiny();
      .display(none);
      .action.close {
        .display(none);
      }
      &.expanded-content {
        .display(flex);
      }
    }
  }
  */
}
