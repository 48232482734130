//////////////////////
// gallery
//////////////////////

// product

.gallery-img {
  .margin(x, auto);
  .responsive {
    width: 100%;
  }
}
.gallery-main {
  .padding(top, big);
  .padding(top, huge, sm);
  .gallery-img-main {
    @media @max-sm {
      display: none;
    }
    @media @min-sm {
      .margin(bottom, big);
    }
  }
  .slider-gallery {
    @media @min-sm {
      display: none;
    }
  }
}
.gallery-main-inner {
  position: relative;
  .btn-round-white {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    @media @max-sm {
      display: none;
    }
  }
}
.gallery-sub {
  .gallery-img-main {
    display: none;
  }
  @media @max-sm {
    display: none;
  }
  @media @min-sm {
    .gallery-img {
      .margin(top, huge);
    }
  }
}