//////////////////////
// various
//////////////////////

.design-setup {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-select-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.accelerate {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  transform: translateZ(0);
}

.sr-only {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

// responsive

.responsive(@breakpoint:none) when (@breakpoint = none) {
  max-width: 100%;
  height: auto;
  /*
  // fix ie11 bug responsive
  min-height: 2px;
  // fix chrome bug responsive
  .align-self-center();
  */
}

.responsive(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    max-width: 100%;
    height: auto;
  }
}

.responsive-y(@breakpoint:none) when (@breakpoint = none) {
  max-height: 100%;
  width: auto;
}

.responsive-y(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    max-height: 100%;
    width: auto;
  }
}

// block

.block(@breakpoint:none) when (@breakpoint = none) {
  display: block;
  width: 100%;
}

.block(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    display: block;
    width: 100%;
  }
}

//////////////////////
// text
//////////////////////

// text-transform

.text-transform(@val, @breakpoint:none) when (@breakpoint = none) {
  text-transform: @val;
}

.text-transform(@val, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    text-transform: @val;
  }
}

// text-align

.text-align(@val, @breakpoint:none) when (@breakpoint = none) {
  text-align: @val;
}

.text-align(@val, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    text-align: @val;
  }
}

//////////////////////
// float
//////////////////////

// float

.float(@val, @breakpoint:none) when (@breakpoint = none) {
  float: @val;
}

.float(@val, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    float: @val;
  }
}

//////////////////////
// displays
//////////////////////

// display

.display(@val, @breakpoint:none) when (@breakpoint = none) {
  & when not (@val = flex), not (@val = inline-flex) {
    display: @val;
  }
  & when (@val = flex) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  & when (@val = inline-flex) {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.display(@val, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    & when not (@val = flex), not (@val = inline-flex) {
      display: @val;
    }
    
    & when (@val = flex) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    
    & when (@val = inline-flex) {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }
}

//////////////////////
// order
//////////////////////

// order

.order(@i, @breakpoint:none) when (@breakpoint = none) {
  @val: extract(@orders, @i + 1);
  -webkit-box-ordinal-group: @val;
  -ms-flex-order: @val;
  order: @val;
}

.order(@i, @breakpoint:none) when (default()) {
  @val: extract(@orders, @i + 1);
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-ordinal-group: @val;
    -ms-flex-order: @val;
    order: @val;
  }
}

//////////////////////
// margin
//////////////////////

// margin

.margin(@pos:all, @size, @breakpoint:none) when (@breakpoint = none) {
  & when (isnumber(@size)) {
    .print-margin(@pos, @size, @breakpoint);
  }
  & when not (isnumber(@size)) {
    .loop-margin(@pos, @size, @breakpoint);
  }
  // layout
  & when (@size = layout) and (@pos = y) {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  & when (@size = layout) and (@pos = x) {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  & when (@size = layout) and (@pos = top) {
    &:first-child {
      margin-top: 0;
    }
  }
  & when (@size = layout) and (@pos = bottom) {
    &:last-child {
      margin-bottom: 0;
    }
  }
  & when (@size = layout) and (@pos = left) {
    &:first-child {
      margin-left: 0;
    }
  }
  & when (@size = layout) and (@pos = right) {
    &:last-child {
      margin-right: 0;
    }
  }
}

.margin(@pos:all, @size, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    & when (isnumber(@size)) {
      .print-margin(@pos, @size, @breakpoint);
    }
    
    & when not (isnumber(@size)) {
      .loop-margin(@pos, @size, @breakpoint);
    }
  }
}

.loop-margin(@pos:all, @size, @breakpoint:none, @i:length(@margins)) when (@i > 0) {
  .loop-margin(@pos, @size, @breakpoint:none, @i - 1);
  @margin: extract(@margins, @i);
  @name: extract(@margin, 1);
  @val: extract(@margin, 2);
  & when (@name = @size) {
    .print-margin(@pos, @val, @breakpoint);
  }
}

.print-margin(@pos, @val, @breakpoint) when (@pos = all) {
  margin: @val;
}
.print-margin(@pos, @val, @breakpoint) when (@pos = y) {
  margin-top: @val;
  margin-bottom: @val;
}
.print-margin(@pos, @val, @breakpoint) when (@pos = x) {
  margin-left: @val;
  margin-right: @val;
}
.print-margin(@pos, @val, @breakpoint) when (@pos = top) {
  margin-top: @val;
}
.print-margin(@pos, @val, @breakpoint) when (@pos = bottom) {
  margin-bottom: @val;
}
.print-margin(@pos, @val, @breakpoint) when (@pos = left) {
  margin-left: @val;
}
.print-margin(@pos, @val, @breakpoint) when (@pos = right) {
  margin-right: @val;
}

// margin-remove

.margin-remove(@pos:all, @size, @breakpoint:none) when (@breakpoint = none) {
  & when (isnumber(@size)) {
    .print-margin-remove(@pos, @size, @breakpoint);
  }
  & when not (isnumber(@size)) {
    .loop-margin-remove(@pos, @size, @breakpoint);
  }
}

.margin-remove(@pos:all, @size, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    & when (isnumber(@size)) {
      .print-margin-remove(@pos, @size, @breakpoint);
    }
    
    & when not (isnumber(@size)) {
      .loop-margin-remove(@pos, @size, @breakpoint);
    }
  }
}

.loop-margin-remove(@pos:all, @size, @breakpoint:none, @i:length(@margins)) when (@i > 0) {
  .loop-margin-remove(@pos, @size, @breakpoint:none, @i - 1);
  @margin: extract(@margins, @i);
  @name: extract(@margin, 1);
  @val: extract(@margin, 2);
  & when (@name = @size) {
    .print-margin-remove(@pos, @val, @breakpoint);
  }
}

.print-margin-remove(@pos, @val, @breakpoint) when (@pos = all) {
  margin: -@val;
}
.print-margin-remove(@pos, @val, @breakpoint) when (@pos = y) {
  margin-top: -@val;
  margin-bottom: -@val;
}
.print-margin-remove(@pos, @val, @breakpoint) when (@pos = x) {
  margin-left: -@val;
  margin-right: -@val;
}
.print-margin-remove(@pos, @val, @breakpoint) when (@pos = top) {
  margin-top: -@val;
}
.print-margin-remove(@pos, @val, @breakpoint) when (@pos = bottom) {
  margin-bottom: -@val;
}
.print-margin-remove(@pos, @val, @breakpoint) when (@pos = left) {
  margin-left: -@val;
}
.print-margin-remove(@pos, @val, @breakpoint) when (@pos = right) {
  margin-right: -@val;
}

//////////////////////
// padding
//////////////////////

// padding

.padding(@pos:all, @size, @breakpoint:none) when (@breakpoint = none) {
  & when (isnumber(@size)) {
    .print-padding(@pos, @size, @breakpoint);
  }
  & when not (isnumber(@size)) {
    .loop-padding(@pos, @size, @breakpoint);
  }
  // layout
  & when (@size = layout) and (@pos = y) {
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  & when (@size = layout) and (@pos = x) {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  & when (@size = layout) and (@pos = top) {
    &:first-child {
      padding-top: 0;
    }
  }
  & when (@size = layout) and (@pos = bottom) {
    &:last-child {
      padding-bottom: 0;
    }
  }
  & when (@size = layout) and (@pos = left) {
    &:first-child {
      padding-left: 0;
    }
  }
  & when (@size = layout) and (@pos = right) {
    &:last-child {
      padding-right: 0;
    }
  }
}

.padding(@pos:all, @size, @breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    & when (isnumber(@size)) {
      .print-padding(@pos, @size, @breakpoint);
    }
    
    & when not (isnumber(@size)) {
      .loop-padding(@pos, @size, @breakpoint);
    }
  }
}

.loop-padding(@pos:all, @size, @breakpoint:none, @i:length(@paddings)) when (@i > 0) {
  .loop-padding(@pos, @size, @breakpoint:none, @i - 1);
  @padding: extract(@paddings, @i);
  @name: extract(@padding, 1);
  @val: extract(@padding, 2);
  & when (@name = @size) {
    .print-padding(@pos, @val, @breakpoint);
  }
}

.print-padding(@pos, @val, @breakpoint) when (@pos = all) {
  padding: @val;
}
.print-padding(@pos, @val, @breakpoint) when (@pos = y) {
  padding-top: @val;
  padding-bottom: @val;
}
.print-padding(@pos, @val, @breakpoint) when (@pos = x) {
  padding-left: @val;
  padding-right: @val;
}
.print-padding(@pos, @val, @breakpoint) when (@pos = top) {
  padding-top: @val;
}
.print-padding(@pos, @val, @breakpoint) when (@pos = bottom) {
  padding-bottom: @val;
}
.print-padding(@pos, @val, @breakpoint) when (@pos = left) {
  padding-left: @val;
}
.print-padding(@pos, @val, @breakpoint) when (@pos = right) {
  padding-right: @val;
}

//////////////////////
// flex space
//////////////////////

// flex space

.flex-space-y(@name, @breakpoint:none) when (@breakpoint = none) {
  @arr: ~"flex-space-@{name}";
  @vertical: extract(@@arr, 1);
  margin-top: -@vertical;
  margin-bottom: -@vertical;
  > * {
    padding-top: @vertical;
    padding-bottom: @vertical;
  }
}

.flex-space-x(@name, @breakpoint:none) when (@breakpoint = none) {
  @arr: ~"flex-space-@{name}";
  @horizontal: extract(@@arr, 2);
  margin-left: -@horizontal;
  margin-right: -@horizontal;
  > * {
    padding-left: @horizontal;
    padding-right: @horizontal;
  }
}

.flex-space(@name, @breakpoint:none) when (@breakpoint = none) {
  @arr: ~"flex-space-@{name}";
  @vertical: extract(@@arr, 1);
  @horizontal: extract(@@arr, 2);
  margin: -@vertical -@horizontal;
  > * {
    padding: @vertical @horizontal;
  }
}

.flex-space(@name, @breakpoint:none) when (default()) {
  @arr: ~"flex-space-@{name}";
  @vertical: extract(@@arr, 1);
  @horizontal: extract(@@arr, 2);
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    margin: -@vertical -@horizontal;
    > * {
      padding: @vertical @horizontal;
    }
  }
}

//////////////////////
// list space
//////////////////////

// list space

.list-space-y(@name, @breakpoint:none) when (@breakpoint = none) {
  @arr: ~"list-space-@{name}";
  @vertical: extract(@@arr, 1);
  margin-top: -@vertical;
  margin-bottom: -@vertical;
  > * {
    margin-top: @vertical;
    margin-bottom: @vertical;
  }
}

.list-space-x(@name, @breakpoint:none) when (@breakpoint = none) {
  @arr: ~"list-space-@{name}";
  @horizontal: extract(@@arr, 2);
  margin-left: -@horizontal;
  margin-right: -@horizontal;
  > * {
    margin-left: @horizontal;
    margin-right: @horizontal;
  }
}

.list-space(@name, @breakpoint:none) when (@breakpoint = none) {
  @arr: ~"list-space-@{name}";
  @vertical: extract(@@arr, 1);
  @horizontal: extract(@@arr, 2);
  margin: -@vertical -@horizontal;
  > * {
    margin: @vertical @horizontal;
  }
}

.list-space(@name, @breakpoint:none) when (default()) {
  @arr: ~"list-space-@{name}";
  @vertical: extract(@@arr, 1);
  @horizontal: extract(@@arr, 2);
  @media @@query-min {
    margin: -@vertical -@horizontal;
    > * {
      margin: @vertical @horizontal;
    }
  }
}

//////////////////////
// list alignment
//////////////////////

// list alignment

.list-left(@breakpoint:none) when (@breakpoint = none) {
  .list-btn-all({ .text-align(left); });
}

.list-left(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    .list-btn-all({ .text-align(left); });
  }
}

.list-center(@breakpoint:none) when (@breakpoint = none) {
  .list-btn-all({ .text-align(center); });
}

.list-center(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    .list-btn-all({ .text-align(center); });
  }
}

.list-right(@breakpoint:none) when (@breakpoint = none) {
  .list-btn-all({ .text-align(right); });
}

.list-right(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    .list-btn-all({ .text-align(right); });
  }
}

//////////////////////
// flex util
//////////////////////

// flex-auto

.flex-auto(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-auto(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

// direction

.flex-row(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-row(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.flex-row-reverse(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-row-reverse(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

.flex-column(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-column(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.flex-column-reverse(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flex-column-reverse(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

// wrap

.flex-wrap(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.flex-wrap-reverse(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-wrap-reverse(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
}

.flex-nowrap(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-nowrap(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

// justify-content

.justify-content-start(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-start(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.justify-content-end(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-end(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.justify-content-center(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-center(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.justify-content-between(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-between(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.justify-content-around(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.justify-content-around(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

// align-items

.align-items-start(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-align: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-start(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-align: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.align-items-end(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-align: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-end(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-align: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

.align-items-center(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-items-center(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.align-items-baseline(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-items-baseline(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

.align-items-stretch(@breakpoint:none) when (@breakpoint = none) {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  > * {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.align-items-stretch(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    > * {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
  }
}

// align-content

.align-content-start(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-start(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
}

.align-content-end(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-end(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
}

.align-content-center(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-center(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-line-pack: center;
    align-content: center;
  }
}

.align-content-between(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-between(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
}

.align-content-around(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-around(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
}

.align-content-stretch(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  > * {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.align-content-stretch(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    > * {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
  }
}

// align-self

.align-self-start(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-start(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}

.align-self-end(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-end(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

.align-self-center(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-item-align: center;
  align-self: center;
}

.align-self-center(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-item-align: center;
    align-self: center;
  }
}

.align-self-baseline(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.align-self-baseline(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
}

.align-self-stretch(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  > * {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.align-self-stretch(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-item-align: stretch;
    align-self: stretch;
    > * {
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
  }
}

.align-self-auto(@breakpoint:none) when (@breakpoint = none) {
  -ms-flex-item-align: auto;
  align-self: auto;
}

.align-self-auto(@breakpoint:none) when (default()) {
  @query-min: 'min-@{breakpoint}';
  @media @@query-min {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
}

//////////////////////
// overlay
//////////////////////

// size

.overlay-size(@name) {
  // .by-key(@overlays, @name);
  @arr: ~"overlay-size-@{name}";
  @val: @@arr;
  .overlay-inner {
    max-width: @val;
  }
}

// generate-overlay-size

.generate-overlay-size();
.generate-overlay-size(@i:length(@overlay-sizes)) when (@i > 0) {
  .generate-overlay-size(@i - 1);
  @name: extract(@overlay-sizes, @i);
  .overlay-size-@{name} {
    .overlay-size(@name) !important;
  }
}

// generate-overlay-space-responsive

.generate-overlay-space-responsive();
.generate-overlay-space-responsive(@i:length(@container-space)) when (@i > 0) {
  .generate-overlay-space-responsive(@i - 1);
  @overlay: extract(@container-space, @i);
  @breakpoint: extract(@overlay, 1);
  @val: extract(@overlay, 2);
  & when (@breakpoint = none) {
    .overlay {
      padding: @val;
    }
  }
  & when not (@breakpoint = none) {
    @query-min: 'min-@{breakpoint}';
    @media @@query-min {
      .overlay {
        padding: @val;
      }
    }
  }
}
