//////////////////////
// colors
//////////////////////

// greys

@white: #fff;
@black: #000;
@grey-200: #F5F5F5;
@grey-250: #F2F2F2;
@grey-300: #EFEFEF;
@grey-350: #e6e6e6;
@grey-400: #B1B1B1;
@grey-500: #717171;
@grey-600: #787878;
@grey-900: #e0e0e0;
@red: #dc0000;

// text

@text: @black;
@text-inverse: @white;

// colors

@primary: @black;
@secondary: @grey-250;

// percents

@perc-xxs: 2.5%;
@perc-xs: 5%;
@perc-sm: 15%;
@perc-md: 25%;
@perc-lg: 50%;
@perc-xl: 90%;

// gradients

@grad-primary: linear-gradient(0deg, darken(@primary, 3%) 10%, @primary 100%);

//////////////////////
// fonts
//////////////////////

.font-sans-regular() {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-sans-medium() {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-sans-semibold() {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.font-sans-bold() {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-monospace() {
  font-family: monaco, Consolas, 'Lucida Console', monospace;
  font-weight: normal;
  font-style: normal;
}

//////////////////////
// utils
//////////////////////

// margins and paddings

@margins: auto auto, none 0, tiny .5rem, small 1rem, medium 2rem, mediumbig 2.5rem, big 4rem, giant 5rem, huge 8rem;
@paddings: auto auto, none 0, tiny .5rem, small 1rem, medium 2rem, mediumbig 2.5rem, big 4rem, giant 5rem, huge 8rem;

// z-index

@index-base: 0;
@index-active: 2;
@index-sticky: 100;
@index-loader: 120;
@index-backdrop: 130;
@index-drop: 150;
@index-overlay: 200;
@index-btn-close: 300;

//////////////////////
// layout
//////////////////////

// order

@orders: 0, 1, 2, 3, 4, 5;

// flex

@flex-spaces: none, tiny, small, medium, big, biggiant, giant; // if you change this change also variables names below
@flex-space-none: 0 0; // vertical horizontal
@flex-space-tiny: .25rem .25rem;
@flex-space-small: .5rem .5rem;
@flex-space-medium: 1rem 1rem;
@flex-space-big: 2rem 2rem;
@flex-space-biggiant: 3rem 3rem;
@flex-space-giant: 5rem 5rem;

// list

@list-spaces: none, tiny, small, medium, big, giant; // if you change this change also variables names below
@list-space-none: 0 0; // vertical horizontal
@list-space-tiny: .25rem .25rem;
@list-space-small: .5rem .5rem;
@list-space-medium: 1rem 1rem;
@list-space-big: 2rem 2rem;
@list-space-giant: 4rem 4rem;

//////////////////////
// icon
//////////////////////

@icon-tiny: .5em;
@icon-small: .75em;
@icon-medium: 1em;
@icon-big: 1.3em;
@icon-giant: 2em;

//////////////////////
// form
//////////////////////

@select-arrow-size: 4px; // in px
@select-space-size: 30px; // in px

//////////////////////
// components
//////////////////////

@border-radius: 0;

// btn

@btn-border-width: 1px;

// drop

@drop-space: 0; // space between button and drop

// overlay

@overlay-sizes: tiny, small, medium, big, giant, full; // if you change this change also variables names below
@overlay-size-tiny: @breakpoint-xs - 60;
@overlay-size-small: 672px;
@overlay-size-medium: @breakpoint-md - 60;
@overlay-size-big: @breakpoint-lg - 60;
@overlay-size-giant: @breakpoint-xl - 60;
@overlay-size-full: none;

//////////////////////
// animations
//////////////////////

// size

@anim-tiny: .5rem;
@anim-small: .75rem;
@anim-medium: 1.5rem;
@anim-big: 3rem;
@anim-giant: 6rem;

// time

@time-tiny: 75ms;
@time-small: 150ms;
@time-medium: 300ms;
@time-big: 450ms;
@time-giant: 600ms;

// beziers

@bez-in: @bez-cat-in;
@bez-out: @bez-cat-out;

@bez-ease: ease; // others at https://easings.net/
@bez-cat-in: ~'cubic-bezier(.41, .1, .175, 1)';
@bez-cat-out: ~'cubic-bezier(.77, 0, .175, 1)';
@bez-frog-in: ~'cubic-bezier(.645, .045, .355, 1.000)';
@bez-frog-out: ~'cubic-bezier(.550, .055, .675, .190)';
@bez-tiger: ~'cubic-bezier(.96, .1, .14, .88)';

//////////////////////
// grid
//////////////////////

@columns: 12;

// breakpoints

@breakpoints: xs, sm, md, lg, xl; // if you change this change also variables names below
@breakpoint-xs: 576px;
@breakpoint-sm: 768px;
@breakpoint-md: 992px;
@breakpoint-lg: 1200px;
@breakpoint-xl: 1300px;

// container

@container-fluid: 1920px; // max-width
@container-space: none 15px, xs 15px, sm 20px, md 25px, lg 30px, xl 30px; // container padding

// @media queries
// USAGE: @media @min-sm and @max-md {}

@min-xs: ~'(min-width:'(@breakpoint-xs)~')';
@max-xs: ~'(max-width:'(@breakpoint-xs - 1)~')';
@min-sm: ~'(min-width:'(@breakpoint-sm)~')';
@max-sm: ~'(max-width:'(@breakpoint-sm - 1)~')';
@min-md: ~'(min-width:'(@breakpoint-md)~')';
@max-md: ~'(max-width:'(@breakpoint-md - 1)~')';
@min-lg: ~'(min-width:'(@breakpoint-lg)~')';
@max-lg: ~'(max-width:'(@breakpoint-lg - 1)~')';
@min-xl: ~'(min-width:'(@breakpoint-xl)~')';
@max-xl: ~'(max-width:'(@breakpoint-xl - 1)~')';
