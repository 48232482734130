//////////////////////
// alert
//////////////////////

.alert {
  .margin(top, medium);
  // box
  .box();
  .box-small();
  .box-noborder();
  .display(block);
  // styles
  border-radius: @border-radius;
  .font-sans-medium();
  line-height: 1.4;
  .text-align(left);
  // options
  .alert-grey(); // variant
}

// variant

.alert-primary {
  .inverse();
  &::before {
    border-color: @primary;
    background-color: @primary;
  }
}

.alert-secondary {
  color: @text;
  &::before {
    border-color: @secondary;
    background-color: @secondary;
  }
}

.alert-white {
  color: @text;
  &::before {
    border-color: @white;
    background-color: @white;
  }
}

.alert-grey {
  color: @text;
  &::before {
    border-color: @grey-250;
    background-color: @grey-250;
  }
}