//////////////////////
// newsletter
//////////////////////

.newsletter-outer {
  border-top: 1px solid @grey-350;
}

// .block.newsletter

.block.newsletter {
  .padding(top, 3rem);
  line-height: 1.8;
  .newsletter-title {
    .font-sans-bold();
    font-size: 28px;
    line-height: 1.2;
    text-transform: uppercase;
    @media @max-sm {
      .padding(bottom, medium) !important;
      text-align: center;
    }
    @media @min-sm {
      max-width: 350px;
    }
  }
  .newsletter-subtitle {
    width: 100%;
    .font-sans-medium();
    font-size: 16px;
    text-transform: uppercase;
  }
  .content {
    .flex-auto();
    @media @max-sm {
      .margin(top, small);
      width: 100%;
    }
  }
}

// #overlay-newsletter

#overlay-newsletter {
  .input-group {
    .margin(bottom, small);
  }
}

#overlay-newsletter-auto {
  .xt-backdrop {
    pointer-events: none;
  }
}
