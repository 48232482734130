//////////////////////
// footer
//////////////////////

// .footer-banner

.footer-banner {
  .padding(y, big);
  line-height: 1.8;
  .footer-banner-item {
    .link-none();
    .padding(y, small);
    @media @max-sm {
      .flex();
      .flex-space(big);
      .flex-nowrap();
      .align-items-center();
    }
    @media @min-sm {
      text-align: center;
    }
  }
  .footer-banner-icon {
    @media @min-sm {
      .margin(bottom, small);
    }
    > * {
      @media @max-sm {
        .icon-giant();
      }
      @media @min-sm {
        .icon-big();
      }
    }
  }
  .footer-banner-title {
    .font-sans-semibold();
    font-size: 12px;
    letter-spacing: .075em;
    text-transform: uppercase;
  }
  .footer-banner-subtitle {
    margin-top: .5rem;
    .font-sans-medium();
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: .075em;
    text-transform: uppercase;
  }
}

// .footer-content

.footer-content {
  .padding(top, big);
  .padding(bottom, medium);
}

// .footer-top

.footer-top {
  .display(none);
  .display(block, sm);
  h6 {
    .font-sans-semibold();
    font-size: 14px;
    color: @black;
  }
  .footer-top-social {
    .margin(top, big, sm);
    ul {
      a {
        font-size: 14px;
        color: @black;
        line-height: 2;
      }
    }
    .icon-big {
      @media @max-sm {
        .icon-giant();
      }
    }
  }
  .footer-top-inner {
    ul {
      a {
        font-size: 14px;
        color: @black;
        line-height: 2;
      }
      @media @max-sm {
        .list-center();
      }
    }
  }
  &.footer-top-mobile {
    .display(block);
    .display(none, sm);
  }
}

// .footer-bottom

.footer-bottom {
  .padding(top, big);
  @media @max-sm {
    .text-align(center);
  }
  .copyright,
  .footer-bottom-left-links {
    display: inline-block;
    .font-sans-regular();
    font-size: 12px;
    line-height: 1.4;
    .btn-tiny {
      font-size: 12px;
      text-transform: uppercase;
    }
    .separator {
      top: 0.1em;
      font-size: 1em;
    }
  }
  .footer-bottom-left-links {
    @media @max-sm {
      display: block;
      .separator:first-child {
        display: none;
      }
    }
    .btn {
      text-transform: uppercase !important;
    }
  }
  .footer-bottom-right {
    .display(none);
    .display(block, sm);
  }
  .footer-payments {
    display: none;
    .font-small();
    opacity: .25;
  }
}

// .footer-content-checkout

.footer-content-checkout {
  .padding(top, mediumbig);
  .padding(bottom, mediumbig);
  .footer-bottom {
    .padding(top, none) !important;
  }
  .footer-bottom-left {
    width: 100%;
    text-align: center;
  }
}
