//////////////////////
// access
//////////////////////

// login

.block-authentication {
  .messages {
    .margin(bottom, medium);
  }
}
.block-customer-login {
  .block-title {
    .display(none);
    .popup-authentication & {
      .display(block);
      .modal-popup .modal-header();
      &:first-child {
        .margin-remove(top, medium) !important;
      }
      strong {
        .modal-popup .modal-title();
      }
    }
  }
  .block-content {
    .field.note {
      margin: 0;
      .reset-text();
      .font-medium() !important;
      line-height: 2 !important;
    }
  }
}

.block-new-customer {
  .margin(top, huge);
  .popup-authentication & {
    .display(none) !important;
  }
  .btn-tall {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }
}

#password-strength-meter-label {
  .font-sans-medium();
  color: @text;
}

// forgot-password

.field.captcha {
  .margin(top, medium);
  .captcha-image {
    .list();
    .list-space(small);
    .align-items-center();
    .justify-content-between();
    // content
    .action {
      .btn-tiny();
    }
  }
}

//////////////////////
// dashboard sidebar
//////////////////////

// .block-collapsible-nav

.sidebar-account {
  @media @max-md {
    display: none;
  }
  .box();
  .box-big();
  .title {
    .margin(bottom, 1.5rem);
    .font-sans-bold();
    font-size: 12px;
    letter-spacing: .05em;
    text-transform: uppercase;
  }
  .nav.items {
    .list();
    .list-block();
    .list-space(none);
    .list-primary-empty();
    .list-tiny();
    .list-none-x();
    li > strong {
      .btn();
      .btn-primary-empty();
      .btn-primary-empty.active();
      .btn-tiny();
      .btn-none-x();
    }
    li.current {
      a, button {
        .btn-primary-empty.active();
        strong {
          .reset-text();
        }
      }
    }
  }
}

//////////////////////
// dashboard
//////////////////////

body.account & {
  .order-title {
    strong {
      .h1();
      .h1.small();
    }
  }
}

// dashboard

body.account {
  // actions
  .box-actions {
    .actions-toolbar();
    .list-space(medium);
    .justify-content-start();
    .margin(top, tiny);
    .action {
      .btn();
      .btn-tiny();
      .btn-none();
      .btn-primary-empty();
    }
  }
  // spacing
  & + .block,
  .actions-toolbar + .block,
  .block + .block {
    .margin(top, big);
    .margin(bottom, big);
  }
  // block
  .block-title {
    .display(flex);
    .align-items-center();
    .justify-content-between();
    .h4-line();
    strong {
      .h4();
      .h4.small();
    }
    .action {
      .btn();
      .btn-tiny();
      .btn-narrow();
      .btn-primary-empty();
    }
  }
  .block-content {
    .flex();
    > .box {
      .col(12);
      .col(6, sm);
      &.box-items {
        .col(12, sm);
      }
      // reset box
      color: inherit;
      &:before {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
  // box
  .box-title {
    .display(inline-block);
    .h6();
    .h6.giant();
  }
  .box-content {
    .font-small();
  }
  // others
  .block-addresses-list {
    .items.addresses {
      .flex();
      .flex-auto();
      li.item {
        .col(12);
        .col(6, md);
        .item-inner {
          .font-small();
        }
      }
      .actions {
        .actions-toolbar();
        .list-space(medium);
        .justify-content-start();
        .margin(top, tiny);
        .action {
          .btn();
          .btn-tiny();
          .btn-none();
          .btn-primary-empty();
        }
      }
    }
  }
  .actions-toolbar {
    .justify-content-start();
    .primary {
      margin-left: 0.25rem !important;
    }
    .secondary {
      display: none;
    }
    /*
    .actions {
      .list();
      .list-space(small);
    }
    .action {
      .btn-tiny();
    }
    */
    .action.back {
      display: none !important;
    }
  }
  .payment-method {
    .dl-line();
  }
}
