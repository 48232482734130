//////////////////////
// animations
//////////////////////

.no-transition {
  transition-property: none !important;
}

.no-animation {
  animation-name: none !important;
}

.no-anim {
  transition-property: none !important;
  animation-name: none !important;
}

//////////////////////
// delay
//////////////////////

/* USAGE:
transition-delay: 150ms; // @start + @child * @increment
.nth-child(@child:6, @start:0ms, @increment:25ms, @decrement:0ms, {
  .optional-nested {
    @animation-delay();
    @transition-delay();
  }
});
*/

.nth-child(@child, @start, @increment, @decrement, @rules) when(@child > 0) {
  .nth-child(@child - 1, @start, @increment, @decrement, @rules);
  &:nth-child(@{child}) {
    @rules();
  }
}

@animation-delay: {
  animation-delay: @start + (@child * @increment) - (@child * @decrement);
};

@transition-delay: {
  transition-delay: @start + (@child * @increment) - (@child * @decrement);
};

//////////////////////
// collapse
//////////////////////

.collapse-height {
  overflow: hidden;
  height: 0;
  // custom property
  --collapse-height: true;
  > * {
    --collapse-height: initial;
  }
}
.collapse-width {
  overflow: hidden;
  width: 0;
  // custom property
  --collapse-width: true;
  > * {
    --collapse-height: initial;
  }
}