//////////////////////
// backdrop
//////////////////////

@backdrop-opacity: .5;

.xt-backdrop {
  // styles
  background-color: darken(@primary, @perc-md);
  // animations
  opacity: 0;
  &.active {
    .animation-default.active();
    animation-name: anim-backdrop-in;
    animation-duration: @time-big;
    opacity: @backdrop-opacity;
  }
  &.out {
    .animation-default.out();
    animation-name: anim-backdrop-out;
    animation-duration: @time-big;
    opacity: 0;
  }
}

@keyframes anim-backdrop-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: @backdrop-opacity;
  }
}

@keyframes anim-backdrop-out {
  0% {
    opacity: @backdrop-opacity;
  }
  100% {
    opacity: 0;
  }
}

//////////////////////
// xt-sticky
//////////////////////

// .header-content-fixed

.header-content-fixed {
  position: sticky;
  z-index: 100;
  top: 0;
  .transition-default.active();
  transition-property: top;
  // animations
  .header-main-left,
  .header-main-right {
    .btn-primary-empty,
    > * > ul > li > a,
    > * > ul > li > button {
      .transition-default.active();
      transform: translateY(0);
      opacity: 1;
      transition-delay: @time-small;
    }
  }
  &.sticky-hide {
    .header-main-left,
    .header-main-right {
      .btn-primary-empty,
      > * > ul > li > a,
      > * > ul > li > button {
        .transition-default.out();
        transform: translateY(-@anim-medium);
        opacity: 0;
        transition-delay: initial;
      }
    }
  }
}

// .hero-mobile

.hero-mobile {
  width: inherit !important;
}

// .product-info-main-inner

.product-info-main-inner {
  .xt-sticky(@mode:'absolute');
  @media @max-sm {
    .xt-sticky-disable();
  }
}