//////////////////////
// document
//////////////////////

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//////////////////////
// ul ol
//////////////////////

ul, ol {
  .reset-list();
}

// .reset-list()

.reset-list() {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: default;
  > li {
    cursor: auto;
  }
}

// selectors

.default-lists(@rules) {
  &:not(.list) {
    &.default-lists, .default-lists & {
      @rules();
    }
  }
}

//////////////////////
// dl
//////////////////////

// .dl-block

dl, .dl-block {
  dt {
    clear: none;
    float: none;
    margin: 0;
  }
  dd {
    float: none;
    margin: 0;
  }
}

// .dl-line

.dl-line {
  .clearfix();
  dt {
    clear: both;
    float: left;
    margin: 0;
  }
  dd {
    display: inline-block;
    float: left;
    margin: 0;
  }
}

//////////////////////
// code
//////////////////////

code {
  text-transform: none;
  white-space: nowrap;
}

pre code {
  float: left;
  white-space: pre-wrap;
}
