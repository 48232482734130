//////////////////////
// spacing
//////////////////////

.col-5x-sm {
  @media @min-sm {
    max-width: 20%;
    width: 20%;
  }
}

// structure

.page-main {
  width: 100%;
  // custom
  body.account &{
    .margin(top, huge, md);
  }
  body.page-print & {
    .margin(top, big);
  }
}

.page-footer {
  .margin(top, big);
  // custom
  .page-layout-checkout & {
    .margin(top, none);
    .padding(top, huge);
  }
}
.page-title-wrapper {
  .margin(bottom, medium);
  // custom
  body.account &,
  .contact-index-index & {
    .margin(bottom, big);
    .page-title {
      .h1();
      .h1.small();
    }
  }
}

// custom layout

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
  .column.main {
    .col(12);
    .col(8, sm);
    .col(7, md);
    .col(6, lg);
    .margin(x, auto);
  }
  .page-title-wrapper {
    .margin(bottom, big) !important;
    .page-title {
      .h4();
      .h4.alt();
    }
  }
  .footer-content-checkout {
    .padding(top, mediumbig);
    .padding(bottom, mediumbig);
    .footer-bottom {
      .padding(top, none) !important;
    }
  }
}

.contact-index-index,
.cms-page-view,
.cms-no-route,
.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-logoutsuccess,
.checkout-cart-index,
.checkout-onepage-success,
.checkout-onepage-failure {
  .page-main {
    .margin(top, huge);
  }
  .page-footer {
    .margin(top, huge);
  }
  .footer-banner {
    display: none;
  }
}

// cms pages styles

.cms-page-view:not(.cms-guide) {
  .column.main {
    h2 {
      .h3();
    }
    h3 {
      .h4();
    }
    h4, h5, h6 {
      .h5();
    }
    table {
      .table();
    }
  }
}

/*
// .sidebar

.sidebar {
  > div + div {
    .margin(top, medium);
  }
}
*/

/*
// .sidebar-additional
.sidebar-additional {
  > div + div {
    .margin(top, 2rem);
  }
  // content
  border-bottom: 1px solid @grey-350;
  .block {
    .padding(top, big);
    border-top: 1px solid @grey-350;
  }
}
*/

//////////////////////
// breadcrumbs
//////////////////////

.breadcrumbs-wrapper {
  .display(none);
}

//////////////////////
// .actions-toolbar
//////////////////////

.actions-toolbar {
  .list();
  .list-space(small);
  .justify-content-end();
  .margin(top, medium);
  div.primary {
    .margin(left, auto) !important; // makes .primary on right and .secondary on left
  }
  a, button {
    .btn-small();
  }
  // custom
  .opc-wrapper &,
  #opc-sidebar & {
    .justify-content-start();
    div.primary {
      margin-left: 0.5rem !important;
    }
  }
  .form.contact &,
  .block-authentication &,
  .customer-account-login &,
  .customer-account-create &,
  .customer-account-forgotpassword & {
    .justify-content-start();
    margin: 0 !important;
    .margin(top, medium) !important;
    .primary {
      margin: 0 !important;
    }
    .secondary {
      display: none;
    }
  }
}

//////////////////////
// toolbar
//////////////////////

// .toolbar

.toolbar {
  /*
  .flex();
  .align-items-center();
  .justify-content-between();
  border-bottom: 1px solid @grey-350;
  // content
  .limiter-text,
  .pages {
    .display(none);
    .margin(top, small);
  }
  .toolbar-amount {
    margin: 0;
    .font-small();
  }
  .toolbar-amount,
  .limiter {
    @media @max-md {
      .display(none) !important;
    }
  }
  // sorter and limiter
  .sorter,
  .limiter {
    margin: 0 !important;
    .form-group-tiny();
    .form-group-inline();
    width: auto;
  }
  .sorter-action {
    .btn();
    .btn-tiny();
    .btn-icon();
    .btn-primary-empty();
    .icon(@content: @icon-arrow-up, @size: @icon-medium, @sr-only: true);
    &.sort-desc {
      .icon(@content: @icon-arrow-down, @size: @icon-medium, @sr-only: true);
    }
    .margin(left, small);
  }
  // modes
  .modes-label {
    .display(none);
  }
  .mode-grid,
  .mode-list {
    .btn();
    .btn-tiny();
    .btn-icon();
    .btn-primary-empty();
  }
  .mode-grid {
    .icon(@content: @icon-grid, @size: @icon-medium, @sr-only: true);
  }
  .mode-list {
    .icon(@content: @icon-list, @size: @icon-medium, @sr-only: true);
  }*/
  // .pager
  .pager {
    .block();
    .flex();
    .align-items-center();
    .justify-content-between();
    .flex-auto();
  }
}

/*
// when after .products.wrapper

.products.wrapper + .toolbar {
  .margin(top, big);
  border: 0;
  border-top: 1px solid @grey-350;
  // show only .pages
  .modes,
  .sorter,
  .limiter,
  .toolbar-amount {
    .display(none);
  }
  .pages {
    .display(block);
  }
}
*/

/*
// when after .table-wrapper

.table-wrapper + .toolbar {
  .margin(top, tiny);
  border: 0;
}
*/

// .pages

.pages {
  .label {
    .display(none);
  }
  // content
  .label {
    .display(none) !important;
  }
  .action.previous {
    margin-right: 1.5rem;
    .icon(@content: @icon-arrow-left, @size: @icon-medium, @sr-only: true);
  }
  .action.next {
    margin-left: 1.5rem;
    .icon(@content: @icon-arrow-right, @size: @icon-medium, @sr-only: true);
  }
  .page {
    .margin(x, .5em) !important;
    .padding(x, .5em) !important;
    .font-sans-regular !important;
    &::after {
      content: '';
      position: absolute;
      top: 52%;
      left: 100%;
      width: 0;
      height: 1px;
      background-color: @black;
      .anim-flow-horizontal.out();
    }
  }
  .page:hover,
  .current .page {
    &::after {
      .anim-flow-horizontal.active();
    }
  }
  .action {
    .btn-round-white() !important;
    font-size: 10px !important;
  }
}

@keyframes anim-page-in {
  0% {
    width: 0;
  }
  100% {
    width: 60%;
  }
}

//////////////////////
// .product-image
//////////////////////

.product-image-container {
  width: auto !important;
  height: auto !important;
  .product-image-wrapper {
    padding: 0 !important;
    img {
      .margin(x, auto);
      .responsive();
    }
  }
}

//////////////////////
// .product-item-name
//////////////////////

.product-item-name {
  .block();
  .h4();
  .margin(bottom, small);
  a {
    .link-none();
    color: @text;
  }
  // custom
  #minicart-content-wrapper &, // minicart
  .opc-block-summary &, // checkout
  .table-order-items & { // account
    .h5();
    @media @max-sm {
      font-size: 14px;
    }
    .margin(bottom, none);
    text-transform: none;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .product-items & { // category
    .margin(bottom, none);
    .font-sans-medium();
    font-size: 1.4rem;
    text-transform: none;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

//////////////////////
// .item-options
//////////////////////

.m2-product-options {
  .dl-line();
  .margin(bottom, none);
  dt::after {
    content: ':';
  }
}

dl.item-options {
  .m2-product-options();
}
dl.product.options {
  .m2-product-options();
}

//////////////////////
// price
//////////////////////

// .price-box

.price-box {
  // custom
  .product-items & { // category
    .margin(top, tiny);
  }
}

// .price

.price-container {
  .block();
  .font-sans-semibold();
  color: @black;
  line-height: 1.8;
  letter-spacing: .05em;
  text-transform: uppercase;
  .price-label {
    .display(none) !important;
  }
  // custom
  #minicart-content-wrapper & { // minicart
    width: auto;
    font-size: 1.4rem;
  }
  .product-items & { // category
    font-size: 1.3rem;
  }
  .product-info-main & { // product
    font-size: 1.8rem;
  }
}

.special-price {
  .price-container {
    .font-sans-bold();
    color: @red;
    // custom
    .product-items & { // category
      font-size: 1.2rem;
    }
  }
}

.old-price {
  .price-container {
    text-decoration: line-through !important;
    // custom
    .product-items & { // category
      font-size: 1.2rem;
    }
  }
}

//////////////////////
// totals
//////////////////////

.table.totals, .table-order-items tfoot {
  th {
    .h6() !important;
    .h6.small() !important;
  }
  td {
    .h5();
  }
}

tr.grand_total,
tr.grand.totals {
  th strong {
    .h5() !important;
    strong {
      .reset-text();
    }
  }
  td {
    .h5();
    strong {
      .reset-text();
    }
  }
}

//////////////////////
// tables
//////////////////////

// all tables

.table-wrapper {
  .field {
    margin: 0;
    .label {
      .display(none);
    }
  }
}

// some tables

.table-wrapper:not(.cart) {
  &:extend(.table-scroll all);
  // columns
  .col.name,
  .col.product,
  .col.item,
  .col.address {
    min-width: 20rem;
  }
  .col.address select {
    min-width: 30rem;
  }
  .col.qty {
    min-width: 15rem;
    .field {
      margin: 0;
    }
  }
  .col.sku,
  .col.qty {
    white-space: nowrap;
  }
  // buttons
  .action {
    .btn();
    .btn-primary-empty();
    .btn-nodesign();
    .btn-tiny();
    .btn-narrow();
    .btn-none-y();
    .padding(left, none);
    white-space: nowrap;
  }
  th .action.edit {
    position: absolute !important;
    top: 0;
    right: 0;
    .btn();
    .btn-tiny();
    .btn-icon();
    .btn-primary-border();
    .icon(@content: @icon-edit, @size: @icon-medium, @sr-only: true);
  }
}

//////////////////////
// .input-group-number
//////////////////////

.input-group-number {
  display: inline-block;
  width: auto;
  border: 1px solid @grey-400;
  .form-group-medium();
  .padding(all, none);
  margin: 0;
  input[type="number"] {
    padding: .8em .8em;
    width: 4em;
    border: 0 !important;
    text-align: center;
    color: @black !important;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  // .btn
  .btn {
    .btn-primary-empty();
    border-color: @secondary;
    background-color: @white;
    font-size: 1.8rem;
    line-height: 0;
  }
  .input-group-line {
    .btn {
      padding: 0;
      width: 1.2em;
      .font-sans-regular();
      > span {
        position: relative;
        top: -.1em;
      }
    }
    .input-number-remove {
      padding-right: 0 !important;
      text-align: right;
    }
    .input-number-add {
      padding-left: 0 !important;
      text-align: left;
    }
  }
  .input-group-stack {
    .btn {
      padding: 0 .3em;
      > span {
        position: relative;
        margin: 0 -1em;
      }
    }
  }
}

//////////////////////
// loader
//////////////////////

._block-content-loading {
  position: relative;
}

.loading-mask {
  .preload-container();
  .preload-background();
  .preload-full();
}

.loader {
  .preload();
  > img,
  > p {
    .display(none);
  }
}

.loading-mask {
  .action.showcart & {
    // custom
    top: -10px;
    left: 0;
    bottom: -1px;
    right: -10px;
    width: auto;
    height: auto;
  }
}

#checkout {
  position: relative;
}
.loading-mask {
  body > &,
  #checkout > & {
    // custom
    .align-items-start();
    .margin(top, medium);
    .padding(all, none) !important;
    z-index: @index-loader + 1;
    .loader {
      position: fixed;
      top: 0;
    }
  }
  body > & {
    &::before {
      display: none;
    }
  }
}

//////////////////////
// messages
//////////////////////

// .messages

.messages-wrapper {
  > .container {
    .container-max();
    /*
    > div {
      // page spacing
      .message {
        .margin(bottom, big) !important;
        form & {
          .margin(bottom, none) !important;
        }
      }
      // sequential spacing
      & + .messages {
        .message {
          .margin(top, -2rem);
        }
      }
    }
    */
  }
}

// .message

.message {
  .alert();
  .margin(top, none);
  & + & {
    .margin(top, tiny);
  }
  // variant
  &.success,
  &.notice,
  &.info {
    .alert-grey();
  }
  &.warning,
  &.error {
    .alert-primary();
  }
  // custom
  .discount-code & {
    .margin(bottom, small);
  }
  body.account & {
    .margin(bottom, medium);
  }
}

// .note

p.note {
  margin-top: 0;
}

//////////////////////
// validation
//////////////////////

// required

.field.required,
.field._required {
  > .label {
    &::after {
      content: '*';
      .margin(left, small);
      color: @primary;
    }
  }
  // @FIX required inside required
  & & {
    > .label {
      &::after {
        .display(none);
      }
    }
  }
}

// input

.input-control {
  &[aria-invalid="true"],
  &.mage-error {
    border-color: @primary;
  }
}

// .note

div.field-error,
div.mage-error {
  .alert();
  .alert-primary();
  .box-tiny();
  .margin(top, none);
  text-align: inherit;
  // space
  .swatch-input ~ &,
  input[type="checkbox"] ~ &,
  input[type="radio"] ~ & {
    .margin(top, small);
  }
  .input-group & {
    position: absolute;
    top: 100%;
  }
}

//////////////////////
// components
//////////////////////

// tooltip

.field-tooltip {
  .note();
}

._with-tooltip {
  .display(flex);
  .flex-column();
  div.field-error {
    .order(1);
  }
  .field-tooltip {
    .order(2);
  }
}

// overlay

.modal-title {
  .margin(bottom, medium);
}
.modal-footer {
  .margin(top, medium);
  text-align: right;
  a, button {
    .margin(left, tiny);
  }
}

// collapse

[role="tablist"],
[role="tabpanel"],
[data-role="collapsible"] {
  &[style="display: flex;"], &[style="display: block;"] {
    // animations
    .anim-vertical.active();
    opacity: 1;
  }
}

// modal

.modal-popup {
  z-index: @index-overlay + 1 !important;
}

//////////////////////
// cookie-alert
//////////////////////

#notice-cookie-block {
  display: none;
  position: fixed;
  z-index: @index-overlay - 1;
  bottom: 0;
  right: 0;
  > .box {
    padding: 1.5em;
  }
  @media @min-sm {
    .padding(all, medium);
    > .box {
      padding: 3em;
      max-width: 45rem;
    }
  }
}

//////////////////////
// cms-content
//////////////////////

.cms-content {
  ul, ol {
    & > li {
      list-style-position: inside;
    }
  }
}
