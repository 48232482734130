//////////////////////
// minicart
//////////////////////

.action.showcart {
  font-size: 1.2rem !important;
  .counter-label {
    .sr-only();
  }
  .counter {
    display: none;
    margin-left: 2px;
    letter-spacing: .1em;
    &.not-empty {
      display: block;
    }
  }
}

// #minicart-content-wrapper

#minicart-content-wrapper {
  .product-item-details {
    flex: 1 1 0;
  }
  .minicart-items-wrapper {
    .margin(y, giant);
    height: auto !important;
  }
  .product-item {
    .margin(y, medium);
    .product-item-photo {
      .link-none();
    }
    .product-image-photo {
      width: 94px !important;
      height: auto !important;
      @media @min-sm {
        width: 119px !important;
      }
    }
    .product-item-pricing {
      .margin(top, small);
    }
    .update-cart-item {
      .display(none);
      //.anim-horizontal.active() !important;
    }
  }
  .totals-wrapper {
    .margin-remove(bottom, small);
    .totals {
      .margin(y, medium);
    }
  }
  .totals-alert {
    font-size: 13px;
    line-height: 1.6;
  }
  .shipping {
    .label {
      .h6();
      .h6.small();
      margin: 0 !important;
    }
    .price-container {
      margin: 0 !important;
      width: auto;
      .h5();
      opacity: 1;
    }
  }
  .subtotal {
    .label {
      .h5();
      margin: 0 !important;
    }
    .price-container {
      margin: 0 !important;
      width: auto;
      .h5();
      font-size: 20px;
      opacity: 1;
    }
  }
  .overlay-dismiss {
    margin-top: 1rem;
  }
}

// #overlay-minicart

#overlay-minicart {
  .overlay-inner,
  #minicart-content-wrapper {
    .display(flex);
    .flex-auto();
  }
  .block-content {
    .display(flex);
    .flex-row();
    .flex-wrap();
    .align-content-between();
  }
  .totals-wrapper {
    width: 100%;
  }
}
