//////////////////////
// link
//////////////////////

.link-underline() {
  text-decoration: underline;
  &:hover, &:active, &.active {
    text-decoration: underline;
    span {
      text-decoration: underline;
    }
  }
}

.link-none() {
  text-decoration: none;
  &:hover, &:active, &.active {
    text-decoration: none;
    span {
      text-decoration: none;
    }
  }
}

// links

a, .alink {
  .link-underline();
  .font-sans-semibold();
  color: @text;
  &:hover, &:active, &.active {
    color: @text;
  }
  // animations
  .transition-default.out();
  &:hover, &:active, &.active {
    .transition-default.active();
  }
}

.btn-link {
  .link-none();
  // reset text
  font-family: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  text-transform: inherit !important;
  // reset .btn styles
  &::before {
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  // custom
  .font-sans-regular() !important;
  color: @text;
  &.btn-link-dark {
    .font-sans-medium() !important;
    color: @text;
  }
  &:hover, &:active, &.active {
    color: @grey-500;
  }
}

// .inverse

.inverse {
  // links color
  a:not(.btn), .alink, .btn-link {
    color: @text-inverse;
    text-decoration: underline;
    &:hover, &:active, &.active {
      color: darken(@text-inverse, @perc-xs);
    }
  }
  // buttons color
  .btn-primary-empty {
    color: @text-inverse !important;
    &:hover, &:active, &.active {
      color: @text-inverse !important;
    }
  }
}

//////////////////////
// buttons
//////////////////////

.btn {
  // styles
  .font-sans-semibold();
  line-height: 1.25;
  letter-spacing: 0;
  text-transform: uppercase;
  &::before {
    .design-setup();
    border-radius: @border-radius;
    border-width: @btn-border-width;
    border-style: solid;
    border-color: transparent;
  }
  // options
  .btn-medium(); // size
}

// animations

.btn {
  .transition-default.out();
  transition-duration: @time-big;
  &::before {
    .transition-default.out();
    transition-duration: @time-big;
  }
  &:hover, &:active, &.active {
    .transition-default.active();
    transition-duration: @time-big;
    &::before {
      .transition-default.active();
      transition-duration: @time-big;
    }
  }
  // icon
  &:hover {
    > .icon-right {
      .anim-left.active();
      animation-duration: @time-big;
    }
    > .icon-left {
      .anim-right.active();
      animation-duration: @time-big;
    }
  }
}

// variant

.btn-primary {
  .link-none();
  color: @white;
  &::before {
    border-color: @primary;
    background-color: @primary;
  }
  &:hover, &:active, &.active {
    color: @white;
    &::before {
      border-color: @primary;
      background-color: @primary;
    }
  }
}

.btn-primary-empty {
  .link-none();
  color: @primary;
  &::before {
    border-color: transparent;
    background-color: transparent;
  }
  &:hover, &:active, &.active {
    color: @primary;
    &::before {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

.btn-primary-border {
  .link-none();
  color: @primary;
  &::before {
    border-color: @primary;
    background-color: transparent;
  }
  &:hover, &:active, &.active {
    color: @white;
    &::before {
      border-color: @primary;
      background-color: @primary;
    }
  }
}

.btn-secondary {
  .link-none();
  color: @text;
  &::before {
    border-color: @secondary;
    background-color: @secondary;
  }
  &:hover, &:active, &.active {
    color: @text;
    &::before {
      border-color: @secondary;
      background-color: @secondary;
    }
  }
}

.btn-white {
  .link-none();
  color: @text;
  &::before {
    border-color: @white;
    background-color: @white;
  }
  &:hover, &:active, &.active {
    color: @text;
    &::before {
      border-color: @white;
      background-color: @white;
    }
  }
}

.btn-round-white {
  .link-none();
  color: @text;
  text-align: center;
  &::before {
    border-radius: 50%;
    border-color: @grey-900;
    background-color: @white;
  }
  &:hover, &:active, &.active {
    color: @text;
    &::before {
      border-color: @grey-900;
      background-color: @white;
    }
  }
  // custom
  padding: 1.325em 0;
  width: 4em;
  height: 4em;
}

.btn-select {
  .link-none();
  .btn-caret();
  .btn-transform();
  .btn-none-x() !important;
  .font-sans-regular();
  color: @text;
  &::before {
    border-width: 0;
    border-bottom-width: 2px;
    border-color: @grey-250;
    background-color: transparent;
  }
  &:hover, &:active, &.active {
    color: @text;
    &::before {
      border-color: @grey-250;
      background-color: transparent;
    }
  }
  // custom
  strong {
    .font-sans-semibold();
  }
}

.btn-newsletter {
  // styles
  margin-right: -1px;
  padding-top: 5px;
  padding-bottom: 20px;
  .font-sans-regular();
  font-size: 11px;
  text-align: center;
  .img {
  }
  .text {
  }
  // btn styles
  .link-none();
  color: @text;
  &::before {
    border-color: transparent;
    background-color: transparent;
  }
  &:hover {
    z-index: @index-active;
    color: @text;
    &::before {
      border-color: @grey-350;
      background-color: transparent;
    }
  }
  &:active, &.active {
    z-index: @index-active;
    color: @text;
    &::before {
      border-color: @primary;
      background-color: transparent;
    }
  }
}

.btn-additional {
  .margin(left, medium);
  .font-sans-regular();
  font-size: 12px;
  .btn-transform();
}

// size

.btn-tiny {
  padding: .75em 1.5em;
  font-size: 11px;
}

.btn-small {
  padding: 1em 1.8em;
  font-size: 12px;
}

.btn-medium {
  padding: 1em 1.8em;
  font-size: 1.325rem; // @FIX odd rem with .025 added
}

.btn-big {
  padding: 1em 1.8em;
  font-size: 1.525rem; // @FIX odd rem with .025 added
}

.btn-giant {
  padding: 1em 1.8em;
  font-size: 1.6rem;
}

// special

.btn-nodesign {
  &::before {
    display: none;
  }
}

.btn-squared {
  &::before {
    border-radius: 0;
  }
}

.btn-tall {
  padding-top: 1.4em;
  padding-bottom: 1.4em;
}

.btn-short {
  padding-top: .6em;
  padding-bottom: .6em;
}

.btn-wide {
  padding-left: 2.2em;
  padding-right: 2.2em;
}

.btn-narrow {
  padding-left: 1em;
  padding-right: 1em;
}

.btn-icon {
  padding: 1em 1.2em;
}

.btn-none {
  padding: 0;
}

.btn-none-x {
  padding-left: 0;
  padding-right: 0;
}

.btn-none-y {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-transform {
  letter-spacing: 0;
  text-transform: none;
}

// custom

.btn-caret {
  .display(flex) !important;
  .align-items-center();
  .justify-content-between();
  .icon(@content: @icon-chevron-down, @size: @icon-tiny, @position: after);
  &::after {
    .margin(left, 2em);
  }
  &.active {
    .icon(@content: @icon-chevron-up, @size: @icon-tiny, @position: after);
    &::after {
      .anim-bottom.active();
      opacity: 1;
    }
  }
  &.out {
    &::after {
      .anim-top.active();
      opacity: 1;
    }
  }
}

.btn-close {
  .btn-primary-empty();
  .icon(@content: @icon-close, @size: @icon-medium, @sr-only: true);
}

.btn-fixempty-sm {
  @media @min-sm {
    .margin-remove(left, 1.8em);
  }
}

.btn-form-link {
  .link-none();
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 0 !important;
  .font-sans-medium();
  .font-small();
  line-height: 1;
}

//////////////////////
// disabled
//////////////////////

[disabled],
.disabled {
  opacity: .25;
}

//////////////////////
// drop
//////////////////////

.drop {
  // styles
  > .drop-inner {
    .padding(y, 1.3rem); // vertical space
    &::before {
      .design-setup();
      border-radius: @border-radius;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      box-shadow: 3px 3px 15px fade(@black, @perc-sm);
    }
    > .list {
      min-width: 18rem;
      .list-btn-all({ .btn-squared(); padding-left: 1.9rem; padding-right: 1.9rem; white-space: nowrap; }); // not overridable
    }
  }
  // options
  .drop-left(); // position
  .drop-bottom(); // position
  .drop-white(); // variant
}

// addons

.drop-container-static {
  > .drop > .drop-inner {
    .padding(top, medium);
    .padding(bottom, big);
    &::before {
      border: 0;
      border-top: 1px solid @grey-350;
      border-bottom: .8rem solid @black;
      box-shadow: none;
    }
  }
}

// @FIX mouseenter

.header-main .drop-container {
  html:not(.preloaded) & {
    pointer-events: none;
  }
  > .btn {
    cursor: default !important;
  }
}

// animations

.drop-container {
  &, > .drop {
    animation-duration: @time-big; // needed for .out animation timing
  }
  &.active {
    > .drop > .drop-inner {
      &::before {
        .anim-horizontal.active();
        opacity: 1;
      }
      & > * {
        .anim-horizontal.active();
        animation-delay: @time-small;
        animation-fill-mode: forwards;
        opacity: 0;
      }
    }
    .xt-backdrop {
      .xt-backdrop.active();
    }
  }
  &.out {
    > .drop > .drop-inner {
      &::before {
        .anim-horizontal.out();
        animation-delay: @time-small;
        animation-fill-mode: forwards;
        opacity: 1;
      }
      & > * {
        .anim-horizontal.out();
        opacity: 0;
      }
    }
    .xt-backdrop {
      .xt-backdrop.out();
    }
  }
}

.drop-disable {
  // disable custom animation
  > .drop > .drop-inner {
    & > * {
      .no-anim();
      opacity: 1 !important;
    }
  }
}

// variant

.drop-white {
  > .drop-inner {
    color: @text;
    &::before {
      border-color: @black;
      background-color: @white;
    }
    > .list-drop {
      .list-btn-default({ .btn-primary-empty() });
    }
  }
}

// special

.drop-squared {
  > .drop-inner {
    &::before {
      border-radius: 0;
    }
  }
}

.drop-noborder {
  > .drop-inner {
    &::before {
      border: 0;
    }
  }
}

// dimensions

.drop-max-height {
  > .drop-inner {
    overflow-y: auto;
    max-height: 20rem;
  }
}

.drop-max-width {
  > .drop-inner {
    overflow-x: auto;
    max-width: 20rem;
  }
}

//////////////////////
// overlay
//////////////////////

.overlay {
  // styles
  .overlay-inner {
    padding: 2.5rem 2rem;
    @media @min-sm {
      padding: 4rem;
    }
    &::before {
      .design-setup();
      border-radius: @border-radius;
      border-width: 0;
      border-style: solid;
      border-color: transparent;
      box-shadow: 0 0 30rem fade(@black, @perc-sm);
    }
    // .btn-close
    > .btn-close {
      position: absolute;
      top: 1.5rem;
      right: 0;
      @media @min-sm {
        top: 3rem;
        right: 1.5rem;
      }
      .btn-giant();
      .btn-icon();
    }
  }
  // options
  .overlay-size(small); // width
  .overlay-white(); // variant
}

// addons

.overlay-screen {
  // styles
  box-shadow: 0 0 30rem fade(@black, @perc-sm);
  .overlay {
    padding: 2.5rem 2rem;
    @media @min-sm {
      padding: 4rem;
    }
    &::before {
      .design-setup();
      border-width: 0;
      border-style: solid;
      border-color: transparent;
    }
    .overlay-inner {
      &::before {
        .display(none);
      }
    }
  }
  // options
  .overlay-size(giant); // size
  .overlay-white(); // variant
  .overlay-squared();
  .overlay-noborder();
}

.overlay-pos {
  // styles
  .overlay {
    padding: 2.5rem 2rem;
    @media @min-sm {
      padding: 4rem;
    }
    .overlay-left& {
      max-width: 510px !important;
    }
    .overlay-right& {
      max-width: 510px !important;
    }
  }
  // options
  .overlay-white(); // variant
  .overlay-squared();
  .overlay-noborder();
}

// custom

.overlay-message {
  position: fixed;
  z-index: @index-overlay !important;
  top: 15rem;
  text-align: center;
  @media @max-sm {
    display: none;
  }
  .overlay-dismiss {
    &::before {
      box-shadow: 3px 3px 15px fade(@black, @perc-sm);
    }
  }
  .overlay-left & {
    right: 0;
    margin-right: 3rem;
  }
  .overlay-right & {
    left: 0;
    margin-left: 3rem;
  }
  // animations
  .overlay-left.active & {
    .anim-right.active();
    opacity: 1;
  }
  .overlay-left.out & {
    .anim-right.out();
    opacity: 0;
  }
  .overlay-right.active & {
    .anim-left.active();
    opacity: 1;
  }
  .overlay-right.out & {
    .anim-left.out();
    opacity: 0;
  }
}

// animations

.overlay-outer {
  animation-duration: @time-big; // needed for .out animation timing
  &.active {
    .overlay-inner {
      &::before {
        .anim-horizontal.active();
        opacity: 1;
      }
      & > * {
        .anim-horizontal.active();
        animation-delay: @time-small;
        animation-fill-mode: forwards;
        opacity: 0;
      }
    }
    .xt-backdrop {
      .xt-backdrop.active();
    }
    // addons
    &.overlay-screen {
      .anim-opacity.active();
      opacity: 1;
    }
    &.overlay-left {
      .overlay-inner {
        &::before {
          .anim-left.active();
          opacity: 1;
        }
        & > * {
          .anim-left.active();
          animation-delay: @time-small;
          animation-fill-mode: forwards;
          opacity: 0;
        }
      }
    }
    &.overlay-right {
      .overlay-inner {
        &::before {
          .anim-right.active();
          opacity: 1;
        }
        & > * {
          .anim-right.active();
          animation-delay: @time-small;
          animation-fill-mode: forwards;
          opacity: 0;
        }
      }
    }
  }
  &.out {
    .overlay-inner {
      &::before {
        .anim-horizontal.out();
        animation-delay: @time-small;
        animation-fill-mode: forwards;
        opacity: 1;
      }
      & > * {
        .anim-horizontal.out();
        opacity: 0;
      }
    }
    .xt-backdrop {
      .xt-backdrop.out();
    }
    // addons
    &.overlay-screen {
      .anim-opacity.out();
      animation-delay: @time-small;
      animation-fill-mode: forwards;
      opacity: 1;
    }
    &.overlay-left {
      .overlay-inner {
        &::before {
          .anim-left.out();
          animation-delay: @time-small;
          animation-fill-mode: forwards;
          opacity: 1;
        }
        & > * {
          .anim-left.out();
          opacity: 0;
        }
      }
    }
    &.overlay-right {
      .overlay-inner {
        &::before {
          .anim-right.out();
          animation-delay: @time-small;
          animation-fill-mode: forwards;
          opacity: 1;
        }
        & > * {
          .anim-right.out();
          opacity: 0;
        }
      }
    }
  }
}

// variant

.overlay-white {
  .overlay-inner,
  &.overlay-screen .overlay {
    color: @text;
    &::before {
      border-color: @black;
      background-color: @white;
    }
    > .btn-close {
      .btn-primary-empty();
    }
  }
}

// special

.overlay-squared {
  .overlay-inner,
  &.overlay-screen .overlay {
    &::before {
      border-radius: 0;
    }
    > .btn-close {
      .btn-squared();
    }
  }
}

.overlay-noborder {
  .overlay-inner,
  &.overlay-screen .overlay {
    &::before {
      border: 0;
    }
  }
}

.overlay-none {
  .overlay-inner,
  &.overlay-screen .overlay {
    padding: 0 !important;
  }
}

//////////////////////
// preload
//////////////////////

// addons

.preload-background {
  &::before {
    .design-setup();
    background-color: @white;
  }
}

.preload-full {
  .padding(x, 33%) !important;
}

// variant

.preload {
  overflow: hidden;
  height: 2px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  &::before {
    z-index: 2;
    width: 100%;
    background-color: @grey-200;
  }
  &::after {
    z-index: 3;
    width: 0;
    background-color: @black;
    // animations
    animation: anim-preload 1200ms infinite linear;
  }
}

@keyframes anim-preload {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
    animation-timing-function: @bez-out;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

// activation

.preload-container {
  .anim-opacity.active();
  opacity: 1;
}

/* USAGE:
<div class="preload-container preload-html preload-relative">
    <div class="preload"></div>
</div>
<div class="preload-container preload-background preload-full">
    <div class="preload"></div>
</div>
*/

/* USAGE:
// xt-preload
document.querySelectorAll('html')[0].classList.add('preloaded');
*/

.main-container, .page-footer {
  visibility: hidden;
  html.preloaded &,
  html.preload-fade & {
    visibility: visible;
  }
}
